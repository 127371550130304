import React, { useMemo, useState } from 'react';
import { DriboStudent } from '@/types/user';
import { getFullName } from '@/logic/user';
import { Icon } from '@/components/icon';

import styled from 'styled-components';
import { UserBadge } from '@/components/badge/user-badge';
import { useAtomValue } from 'jotai';
import { studentsAtom } from '@/logic/students/atoms';
import { SearchBar } from '@/components/search-bar';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const StudentCardContainer = styled.div`
  &.student-card {
    padding: 0.4rem 1.6rem;
    border: none !important;
  }

  .student-card {
    &__line {
      width: 100%;
      padding: 0.8rem;
      display: flex;
      align-items: center;
      gap: 2rem;
      border-radius: 1.2rem;
      &:hover {
        background-color: var(--grey-grey-21);
      }
    }

    &__details {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      gap: 0.4rem;
    }

    &__name {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
    }

    &__info {
      display: flex;
      color: var(--grey-grey-8);
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.8rem;
      gap: 2rem;

      > span {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        min-width: 9.7rem;
      }
    }
  }
`;

const StudentCard: React.FC<{ student: DriboStudent; onClick: () => void }> = ({
  student,
  onClick,
}) => (
  <StudentCardContainer className="student-card" onClick={onClick}>
    <div className="student-card__line">
      <UserBadge user={student} size="large" />
      <div className="student-card__details">
        <div className="student-card__name">{getFullName(student)}</div>
        <div className="student-card__info">
          <span>
            <Icon name="id_card" size="small" />
            {student.dni}
          </span>
          <span>
            <Icon name="call" size="small" />
            {student.phone}
          </span>
        </div>
      </div>
    </div>
  </StudentCardContainer>
);

const SearchStudentContainer = styled.article`
  position: relative;
  height: calc(100vh - 50px);
  overflow: hidden;

  .search-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 7.2rem;
    padding: 1.2rem 1.6rem;
    background-color: white;
    z-index: 10;

    &--with-shadow {
      box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.04);
    }
  }

  .search-list {
    height: 100%;
    overflow-y: scroll;
    padding-top: 8.4rem;
  }
`;

type SearchStudentProps = {
  onStudentSelected?: (studnet: DriboStudent) => void;
};

export const SearchStudent: React.FC<SearchStudentProps> = ({ onStudentSelected = () => {} }) => {
  const students = useAtomValue(studentsAtom);
  const [query, setQuery] = useState('');
  const [hasShadow, setHasShadow] = useState(false);

  const filteredStudents = useMemo(() => {
    if (!query) return students;
    return students.filter((student) => {
      return getFullName(student).toLowerCase().includes(query.toLowerCase());
    });
  }, [query, students]);

  const { t } = useTranslation('assign-practice');

  return (
    <SearchStudentContainer>
      <section className={clsx('search-bar', hasShadow && 'search-bar--with-shadow')}>
        <SearchBar placeholder={t('search')} onChange={setQuery} />
      </section>
      <section
        className="search-list"
        onScroll={(e) => {
          if (!hasShadow && (e.target as any).scrollTop > 1) {
            setHasShadow(true);
          }

          if (hasShadow && (e.target as any).scrollTop < 1) {
            setHasShadow(false);
          }
        }}
      >
        {filteredStudents.map((student) => (
          <StudentCard
            key={student.uid}
            student={student}
            onClick={() => onStudentSelected(student)}
          />
        ))}
      </section>
    </SearchStudentContainer>
  );
};
