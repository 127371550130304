import React, { useState, useRef, useEffect, useMemo, SyntheticEvent } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { useLongPress } from 'use-long-press';
import { IonAvatar, IonLabel, IonItem } from '@ionic/react';
import { DateUtils } from '@/services/date';
import { isEllipsisActive } from '@/utils';
import type { BookedSlotWithRange, PracticeUser } from '@/types/practice';
import { Radiobox } from '@/components/check-indicator';
import { Icon } from '@/components/icon';
import { UserBadge } from '@/components/badge/user-badge';
import { getSlotStatus } from '@/logic/slot';
import {
  multiSelectEnabledAtom,
  selectedSlotsIdAtom,
  selectedSlotsStatusAtom,
} from '@/logic/multi-select/atoms';

interface PracticeSlotProps {
  cellHeight: number;
  practice: BookedSlotWithRange;
  onSlotClicked: () => void;
  teacherPermissions: {
    cancel?: boolean;
    assign?: boolean;
    lock?: boolean;
  };
  onDotsClicked: () => void;
  onLongPress: () => void;
  currentDay: string;
  isMulticar: boolean;
}

export const PracticeSlot: React.FC<PracticeSlotProps> = ({
  cellHeight,
  practice,
  onSlotClicked,
  teacherPermissions,
  onDotsClicked,
  onLongPress,
  currentDay,
  isMulticar,
}) => {
  const { t } = useTranslation('calendar');
  const [showLockReason, setShowLockReason] = useState(false);
  const [isHandlingLongPress, setIsHandlingLongPress] = useState(false);
  const [, setEllipsis] = useState(false);

  const duration = DateUtils.getDuration(practice.start, practice.end);
  const slotHeight = (cellHeight * duration) / 60;
  const currMinutes = DateUtils.getMinutes(practice.start);
  const currHour = DateUtils.getHour(practice.start);
  const slotPosition = (cellHeight * currMinutes) / 60 + (currHour - 6) * cellHeight;
  const slotTextRef = useRef<HTMLSpanElement>(null);
  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const selectedSlotsStatus = useAtomValue(selectedSlotsStatusAtom);
  const selectedSlotsId = useAtomValue(selectedSlotsIdAtom);
  const selectionStatus = useAtomValue(selectedSlotsStatusAtom);

  useEffect(() => {
    if (slotTextRef.current) {
      setEllipsis(isEllipsisActive(slotTextRef.current));
    }
  }, []);

  const getName = (user: PracticeUser) => {
    if (user.lastName) {
      return [user.name, user.lastName].join(' ').toLowerCase();
    }
    return user.name.toLowerCase();
  };

  const setSlotAvatar = () => {
    switch (slotStatus) {
      case 'free':
        return <Icon name="event_available" />;
      case 'pastFree':
        return <Icon name="event_busy" />;
      case 'locked':
        return <Icon name="lock" />;
      case 'pre-booked':
        return <Icon name="person_alert" />;
      case 'no-show':
        return <Icon name="person_cancel" />;
      default:
        return;
    }
  };

  const setSlotLabel = (slot: BookedSlotWithRange) => {
    return [slot.start, t('to'), slot.end].join(' ');
  };

  const setSlotText = (slot: BookedSlotWithRange) => {
    if (!slot.user) {
      if (!DateUtils.isFuture(currentDay, slot.start)) return t('pastFree');
      return t('free');
    }
    if (slot.user && slot.user.role !== 'school') {
      return getName(slot.user) || slot.user.email;
    }
    if (slot.user && slot.user.role === 'school') {
      return t('locked');
    }
    return [slot.start, t('to'), slot.end].join(' ');
  };

  const isLocked = (practice: BookedSlotWithRange) => {
    return practice.user && practice.user.role === 'school' && practice.status !== 'pre-booked';
  };

  const isBooked = (practice: BookedSlotWithRange) => {
    return practice.user && practice.user.role !== 'school';
  };

  const slotStatus = useMemo(() => {
    if (isLocked(practice)) return 'locked';
    else if (practice.notAppeared) return 'no-show';
    else if (isBooked(practice) && practice.status === 'pre-booked') return 'pre-booked';
    else if (isBooked(practice)) return 'booked';
    else if (!isBooked(practice) && !DateUtils.isFuture(currentDay, practice.start))
      return 'pastFree';
    else return 'free';
  }, [practice]);

  const isNoShow = useMemo(() => practice.notAppeared, [practice]);

  const displayActionDots = (practice: BookedSlotWithRange) => {
    const { cancel, lock, assign } = teacherPermissions;
    if (multiSelectEnabled) return false;

    //If teacher doesn't have any permission and is past day shouldn't show dots
    if ((!cancel && !lock && !assign) || DateUtils.isPastDay(currentDay)) return false;

    //If is booked and is future, check cancel permission
    if (isBooked(practice) && DateUtils.isFuture(currentDay, practice.start)) return cancel;

    //If isn't booked or is locked and is future, check lock permission
    if (!isBooked(practice) && DateUtils.isFuture(currentDay, practice.start)) {
      if (isLocked(practice)) return lock;
      if (!isLocked(practice)) return assign || lock;
    }

    //Either return false
    return false;
  };

  const displayMultiSelectChecks = useMemo(() => {
    return multiSelectEnabled && getSlotStatus(practice) === selectedSlotsStatus;
  }, [multiSelectEnabled, selectedSlotsStatus, practice]);

  const isSelectedMulti = useMemo(() => {
    return selectedSlotsId.includes(practice.__front_uid__);
  }, [selectedSlotsId]);

  const toggleLockReason = () => {
    setShowLockReason(!showLockReason);
  };

  useEffect(() => {
    if (isSelectedMulti && showLockReason) {
      setShowLockReason(false);
    }
  }, [isSelectedMulti, showLockReason]);

  const renderPrebookCountdown = (slot: BookedSlotWithRange) => {
    if (
      !slot.expires ||
      slot.status !== 'pre-booked' ||
      (slot.expires && slot.expires < Date.now())
    )
      return;
    const now = DateUtils.createDateTime.local();
    const end = DateUtils.createDateTime.fromMillis(slot.expires);

    return (
      <span className="inline-badge countdown">
        {Math.ceil(end.diff(now, 'minutes').toObject().minutes || 0)}&apos;
      </span>
    );
  };

  const handleDotsClick = () => {
    if (isHandlingLongPress) return;

    if (showLockReason) toggleLockReason();
    onDotsClicked();
  };

  const handleOnClickEvent = (event: SyntheticEvent) => {
    if (isHandlingLongPress) return;

    // TODO: refactor previous click functionality
    if (
      event.target &&
      event.target &&
      typeof (event.target as HTMLElement).className.includes === 'function' &&
      (event.target as HTMLElement).className.includes('dots')
    )
      return;
    handleSlotSingleClick();
  };

  const handleSlotSingleClick = () => {
    if (isLocked(practice) && practice.status !== 'pre-booked' && !multiSelectEnabled) {
      return toggleLockReason();
    }

    if (practice.status === 'pre-booked' && !multiSelectEnabled) return;

    return onSlotClicked();
  };

  const bind = useLongPress(
    () => {
      setIsHandlingLongPress(true);
      onLongPress();
    },
    {
      onFinish: () => {
        requestAnimationFrame(() => {
          setIsHandlingLongPress(false);
        });
      },
    },
  );

  const longPressHandlers = useMemo(() => bind(), [bind]);

  const canBeMultiselected = useMemo(() => {
    return getSlotStatus(practice) === selectionStatus;
  }, [selectionStatus, practice]);

  return (
    <PracticeSlotContainer {...longPressHandlers} onClick={handleOnClickEvent}>
      <div
        className={clsx(
          'practice-item flex between absolute width-100',
          isLocked(practice) && 'locked',
          showLockReason && 'flex-vertical show-reason',
          multiSelectEnabled && 'within-multi',
          multiSelectEnabled && !canBeMultiselected && 'not-selectable',
          multiSelectEnabled && isSelectedMulti && 'selected',
        )}
        style={{ height: slotHeight - 4, top: slotPosition }}
      >
        <IonItem lines="none" class="ion-no-padding flex align-items-center">
          {isBooked(practice) && practice.status !== 'pre-booked' ? (
            <div className="relative">
              {isNoShow ? (
                <IonAvatar
                  className={clsx('avatar relative flex', duration < 45 && 'scaled', slotStatus)}
                >
                  {setSlotAvatar()}
                </IonAvatar>
              ) : (
                <UserBadge
                  user={practice.user!}
                  style={{ marginRight: '1.4rem' }}
                  size="medium"
                  selected={isSelectedMulti}
                />
              )}
            </div>
          ) : (
            <IonAvatar
              className={clsx('avatar relative flex', duration < 45 && 'scaled', slotStatus)}
            >
              {setSlotAvatar()}
            </IonAvatar>
          )}
          <IonLabel class="flex align-items-center between width-100">
            <div
              className={`${
                duration < 45
                  ? 'flex align-items-baseline width-100 label-small hidden ellipsis'
                  : 'flex-vertical hidden ellipsis'
              }`}
            >
              <p
                className={clsx(
                  'slot-label medium hidden ellipsis',
                  duration < 45 && 'medium-important',
                  isBooked(practice) && 'capitalize',
                )}
              >
                {setSlotText(practice)} {duration >= 45 ? renderPrebookCountdown(practice) : null}
                {isNoShow && <span className="inline-badge no-show">{t('notAppeared')}</span>}
              </p>
              <span ref={slotTextRef} className="slot-time hidden ellipsis block">
                {setSlotLabel(practice)}
                {isMulticar && practice.checkpoint && (
                  <span className="checkpoint text-light">
                    <Icon
                      name="location_on"
                      color="rgb(112, 112, 112)"
                      size="small"
                      fill
                      style={{ fontSize: '14px' }}
                    />
                    <span>{practice.checkpoint.name}</span>
                  </span>
                )}
              </span>
            </div>
            <div className="flex actions-wrapper">
              {duration < 45 ? renderPrebookCountdown(practice) : null}
              {displayActionDots(practice) ? (
                <Icon
                  className="dots"
                  name="more_vert"
                  color="var(--grey-grey-11)"
                  size="large"
                  onClick={handleDotsClick}
                  clickable
                />
              ) : null}
              {displayMultiSelectChecks && <Radiobox checked={isSelectedMulti} />}
            </div>
          </IonLabel>
        </IonItem>
        {showLockReason ? (
          <div>
            <hr />
            <div>
              <p className="small-paragraph-title uppercase">{t('lockReason')}</p>
              <p className="small-paragraph-text reason">{practice.reason}</p>
            </div>
          </div>
        ) : null}
      </div>
    </PracticeSlotContainer>
  );
};

const PracticeSlotContainer = styled.div`
  p {
    margin: 0;
  }

  .actions-wrapper {
    align-items: center;
    width: auto;
  }

  .practice-item {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    border-radius: 1.2rem;
    width: 100%;
    background-color: white;
    z-index: 3;
    padding-left: 0.8rem;
    padding-top: 0.8rem;
  }

  .practice-item.selected .avatar {
    background-color: var(--primary-primary-50);
    color: var(--primary-primary-700);
  }

  .practice-item.not-selectable {
    opacity: 0.5;
  }

  .practice-item.locked {
    background-color: var(--grey-grey-41);
  }

  .practice-item.within-multi.locked {
    background-color: white;
  }

  .avatar {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1.4rem;
    width: 4.4rem;
    height: 4.4rem;
    align-items: center;
    justify-content: center;
  }

  .avatar.free {
    background-color: var(--auxiliares-green-1);
    color: var(--auxiliares-green-2);
  }

  .avatar.pastFree {
    background-color: var(--ion-color-light-silver);
    color: var(--grey-grey-8);
  }

  .avatar.locked {
    background-color: var(--grey-grey-5);
    color: var(--base-black);
  }

  .avatar.booked {
    background-color: #46e2ff;
  }

  .avatar.pre-booked {
    background-color: var(--auxiliares-yellow-1);
    color: var(--auxiliares-yellow-2);
  }

  .avatar.no-show {
    background-color: var(--auxiliares-red-1);
    color: var(--auxiliares-red-2);
  }

  .avatar .icon {
    width: 2.4rem;
    height: 2.4rem;
    margin: auto;
  }

  .eventAvailableIcon {
    fill: var(--ion-color-dark-green);
  }

  .lockIcon {
    fill: #525252;
  }

  .scaled {
    transform: scale(0.7);
    margin-right: 0;
    margin-left: -0.7rem;
    padding: 0;
  }

  .scaled .avatar {
    margin-right: 0;
  }

  ion-item {
    flex-direction: column;
    --background: transparent;
    width: 100%;
    --inner-padding-end: 1rem;
  }

  ion-item::part(native) {
    width: 100%;
  }

  ion-label {
    margin: 0;
  }

  .checkpoint {
    margin-left: 0.7rem;
    position: relative;
  }

  .checkpoint ion-icon {
    height: 1.3rem;
    position: relative;
    top: 1px;
  }

  ion-label > * {
    margin-right: 0.6rem !important;
  }

  .label-small {
    margin-left: 0.5rem;
  }

  p.slot-label {
    display: flex;
    color: var(--base-black);
    margin-right: 0.7rem;
    width: max-content;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 18px;
  }

  .slot-time {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--grey-grey-11);
  }

  .text-light {
    color: var(--ion-color-light-text);
  }

  .show-reason {
    height: auto !important;
    z-index: 10;
    justify-content: initial;
    padding-right: 1rem;
  }

  .show-reason ion-item {
    margin-top: 0;
    margin-bottom: 0.7rem;
    --inner-padding-end: 0;
  }

  .show-reason hr {
    margin-bottom: 1.2rem;
    width: 100%;
  }

  .show-reason .reason {
    margin-top: 0.2rem !important;
    margin-bottom: 1rem;
  }

  .inline-badge {
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 0rem 0.4rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    border-radius: 3.3rem;
    margin-left: 0.8rem;

    &.countdown {
      background-color: #ae8000;
    }

    &.no-show {
      background-color: var(--auxiliares-red-2);
    }
  }

  .practice-item.selected .inline-badge {
    background-color: var(--primary-primary-700);
  }
`;
