import firebaseService, { callFunction, FirebaseListenerCallback } from './firebase-adapter';
import { fetchCars, fetchCheckpoints } from './school';
import utils from '../utils';
import type { DriboTeacher } from '@/types/user';
import type {
  BookedSlotWithRange,
  DayPlan,
  DriboPracticeDetail,
  TimeSlotData,
} from '@/types/practice';
import type { PerformTeacherActionResponse } from '@/types/functions';

const planningPath = (teacher: DriboTeacher, car: string, day: string) =>
  `practicesAvailability/${teacher.schoolUid}/${car}/${formatISO(day)}`;
const detailPath = (schoolId: string, practiceId: string) =>
  `schoolPractices/${schoolId}/${practiceId}`;
const userPracticePath = (userId: string, practiceId: string) =>
  `practices/${userId}/${practiceId}/details`;

const formatISO = (date: string) => {
  const isoDate = date.split('T').shift();
  return isoDate!.replace(/-/g, '/');
};

export const getTeacherCar = (teacher: DriboTeacher) => {
  return fetchCars(teacher.schoolUid).then((cars) => {
    return Object.values(cars).find((car) => {
      const timetables = car.timetables;
      if (timetables) {
        if (
          Object.values(timetables).some((timetable: any) =>
            timetable.teachers.split(',').includes(teacher.uid),
          )
        ) {
          return car;
        }
      }
      return null;
    });
  });
};

export const fetchTeacherBookedPractices = (teacher: DriboTeacher, carId: string, day: string) => {
  return firebaseService
    .databaseGet<DayPlan>(planningPath(teacher, carId, day))
    .then((dayPlan) => getBookedByTeacher(teacher, dayPlan, day));
};

export const fetchDetail = (schoolId: string, practiceId: string) => {
  return firebaseService.databaseGet<DriboPracticeDetail>(detailPath(schoolId, practiceId));
};

type PracticeDetailListenerCallback = (result: DriboPracticeDetail | null) => void;
export const listenPracticeDetail = (
  schoolId: string,
  practiceId: string,
  cbk: PracticeDetailListenerCallback,
) => firebaseService.databaseChange(detailPath(schoolId, practiceId), cbk as any);

const _generateSlotUID = (date: string, curr: string, dayPlan: DayPlan) =>
  `D${date}S${curr}E${dayPlan.timeslots[curr].end}`;

export const getBookedByTeacher = async (teacher: DriboTeacher, dayPlan: DayPlan, day: string) => {
  const { schoolUid: schoolId, uid: teacherUid } = teacher;
  if (!dayPlan) return [];
  const date = day.split('T').shift() || '';
  const timeslotsArr = utils.toArray<TimeSlotData>(dayPlan.timeslots);
  const checkpoints = await fetchCheckpoints(schoolId);
  const teacherSlots = timeslotsArr
    .filter((slot) => slot.teacher === teacherUid)
    .map((slot) => slot.start)
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));
  const bookedSlots = teacherSlots.reduce<BookedSlotWithRange[]>((ac, curr) => {
    if (dayPlan.booked) {
      const bookedSlot = dayPlan.booked[curr];
      if (bookedSlot)
        ac.push({
          __front_uid__: _generateSlotUID(date, curr, dayPlan),
          ...bookedSlot,
          date,
          day,
          start: curr,
          end: dayPlan.timeslots[curr].end,
          checkpoint: bookedSlot.checkpointId
            ? checkpoints[bookedSlot.checkpointId]
            : checkpoints[dayPlan.timeslots[curr].checkpoint],
        });
      else
        ac.push({
          __front_uid__: _generateSlotUID(date, curr, dayPlan),
          date,
          day,
          start: curr,
          end: dayPlan.timeslots[curr].end,
        });
    } else
      ac.push({
        __front_uid__: _generateSlotUID(date, curr, dayPlan),
        date,
        day,
        start: curr,
        end: dayPlan.timeslots[curr].end,
      });
    return ac;
  }, [] as BookedSlotWithRange[]);
  return bookedSlots;
};

export const listenTeacherPractices = (
  teacher: DriboTeacher,
  carId: string,
  day: string,
  cbk: FirebaseListenerCallback,
) => {
  return firebaseService.listen(planningPath(teacher, carId, day) + '/booked', cbk);
};

export const updateUserPractice = (userId: string, practiceId: string, payload: any) =>
  firebaseService.update(userPracticePath(userId, practiceId), payload);

export const updateTeacherPractice = (
  teacher: DriboTeacher,
  carId: string,
  day: string,
  start: string,
  payload: any,
) => {
  return firebaseService.update(planningPath(teacher, carId, day) + `/booked/${start}`, payload);
};

export const cancelUserPractice = (
  schoolId: string,
  carId: string,
  studentId: string,
  practiceId: string,
  cancelReason: string,
) => {
  return firebaseService.callFunction<PerformTeacherActionResponse>(
    'practices-performPracticeAction',
    {
      action: 'CANCEL',
      schoolId,
      carId,
      studentId,
      practiceId,
      reason: cancelReason,
    },
  );
};

/**
 * Assigns a practice to a student for a limited time
 * the practice either be booked, or will be available at the end
 * of the time
 */
export const assignPracticeToStudent = async (
  studentId: string,
  schoolId: string,
  carId: string,
  practiceDate: string,
  practiceStart: string,
) => {
  return callFunction<{ status: boolean; message?: string }>('practices-performPracticeAction', {
    action: 'ASSIGN',
    studentId,
    schoolId,
    carId,
    practiceDate,
    practiceStart,
  });
};

export const unassignPractice = async (
  schoolId: string,
  carId: string,
  studentId: string,
  practiceId: string,
) => {
  return callFunction<{ status: boolean; message?: string }>('practices-performPracticeAction', {
    action: 'UNASSIGN',
    schoolId,
    carId,
    studentId,
    practiceId,
  });
};

export const lockTeacherSlot = (
  practiceDate: string,
  practiceStart: string,
  schoolId: string,
  carId: string,
  reason: string,
) => {
  return firebaseService.callFunction<PerformTeacherActionResponse>(
    'practices-performPracticeAction',
    {
      action: 'LOCK',
      practiceDate,
      practiceStart,
      schoolId,
      carId,
      reason,
    },
  );
};

export const unlockTeacherSlot = (
  practiceDate: string,
  practiceStart: string,
  schoolId: string,
  carId: string,
) => {
  return firebaseService.callFunction<PerformTeacherActionResponse>(
    'practices-performPracticeAction',
    {
      action: 'UNLOCK',
      practiceDate,
      practiceStart,
      schoolId,
      carId,
    },
  );
};

export default {
  updateUserPractice,
  listenTeacherPractices,
  getBookedByTeacher,
  listenPracticeDetail,
  fetchTeacherBookedPractices,
  fetchDetail,
  updateTeacherPractice,
  cancelUserPractice,
  lockTeacherSlot,
  unlockTeacherSlot,
};
