import React, { useMemo } from 'react';
import { IonButton } from '@ionic/react';
import {
  multiSelectEnabledAtom,
  selectedActionAtom,
  selectedSlotsStatusAtom,
} from '@/logic/multi-select/atoms';
import { useHistory } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import styled from 'styled-components';
import { Icon } from '@/components/icon';
import { SlotAction, getActionsForStatus, getSlotStatus } from '@/logic/slot';
import { useTranslation } from 'react-i18next';
import { SafeInsetBottom } from '@/components/spacing/safe-inset-bottom';
import { selectedSlotAtom } from '@/logic/single-action/atoms';
import clsx from 'clsx';
import { handleSingleAction } from '@/logic/single-action/handle-single-action';
import { useDribo } from '@/contexts/driboContext';
import { Backdrop } from '@/components/backdrop';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background-color: white;
  transition: transform 0.4s cubic-bezier(0.36, 0.66, 0.04, 1),
    opacity 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);
  transform: translateY(100%);
  opacity: 0;
  padding: 1.2rem 1.6rem;

  .actions-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-container {
    display: flex;
    width: 100%;
    max-width: 500px;

    gap: 1.6rem;
  }

  &.open {
    transform: translateY(0%);
    opacity: 1;
  }

  ion-button {
    width: 100%;
    height: 4.8rem;
    --background: var(--primary-primary-25);
    --color: var(--primary-primary-500);
    --border-radius: 2.4rem;
    --box-shadow: none;
    --padding-top: 1.2rem;
    --padding-bottom: 1.2rem;
    --padding-start: 2.4rem;
    --padding-end: 2.4rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem;
  }
`;

export const BottomActionsWrapper = () => {
  const singleSlotSelected = useAtomValue(selectedSlotAtom);

  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const selectedSlotsStatus = useAtomValue(selectedSlotsStatusAtom);

  const setMultiSelectAction = useSetAtom(selectedActionAtom);
  const { t } = useTranslation('multi-select');
  const { selectedCar: car } = useDribo();

  const history = useHistory();

  const actions = useMemo(() => {
    const selectionStatus = singleSlotSelected
      ? getSlotStatus(singleSlotSelected)
      : selectedSlotsStatus;
    return getActionsForStatus(selectionStatus);
  }, [selectedSlotsStatus, singleSlotSelected]);

  const handleAction = (action: SlotAction) => {
    if (multiSelectEnabled) {
      setMultiSelectAction(action);
      history.push('/review');
    } else {
      handleSingleAction(action, history, t, car);
    }
  };

  const showBottomActions = useMemo(() => {
    return multiSelectEnabled || !!singleSlotSelected;
  }, [multiSelectEnabled, singleSlotSelected]);

  return (
    <ActionsContainer className={clsx(showBottomActions && 'open')}>
      <div className="actions-buttons">
        <div className="button-container">
          {actions.map(({ action, icon }) => (
            <IonButton key={`action-${action}`} onClick={() => handleAction(action)}>
              <Icon name={icon} size="large" />
              <span style={{ width: '8px' }} />
              {t(`actions.${action}`)}
            </IonButton>
          ))}
        </div>
      </div>
      <SafeInsetBottom />
    </ActionsContainer>
  );
};

export const BottomActions = () => {
  const [selectedSlot, setSelectedSlot] = useAtom(selectedSlotAtom);

  const handleBackdropDiscard = () => {
    setSelectedSlot(null);
  };

  return (
    <>
      <Backdrop visible={!!selectedSlot} onDiscard={handleBackdropDiscard} />
      <BottomActionsWrapper />
    </>
  );
};
