import React, { useMemo } from 'react';
import { DateUtils } from '@/services/date';
import { IonFabButton } from '@ionic/react';
import styled from 'styled-components';
import { useAtomValue } from 'jotai';
import { daysWithSelectionAtom } from '@/logic/multi-select/atoms';

type CalendarHeaderDayProps = {
  selectedDay: string;
  number: string;
  name: string;
  iso: string;
  onClick?: () => void;
};

const DotContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  height: 0.4rem;
`;

const ContainsSelectionsDot = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  background-color: var(--grey-grey-8);
  border-radius: 50%;
`;

export const CalendarHeaderDay: React.FC<CalendarHeaderDayProps> = ({
  iso,
  name,
  number,
  selectedDay,
  onClick,
}) => {
  const daysWithSelection = useAtomValue(daysWithSelectionAtom);
  const isSelectedDay = DateUtils.isSameDay(iso, selectedDay);
  const past = DateUtils.isPast(iso);

  const containsSelections = useMemo(
    () => daysWithSelection.some((day) => DateUtils.isSameDay(iso, day)),
    [daysWithSelection],
  );

  const weekday = useMemo(() => {
    return name[0];
  }, [name]);

  return (
    <div
      key={iso}
      className={`center flex-vertical ${isSelectedDay ? 'selected' : ''} ${
        past ? 'past' : 'dark'
      }`}
      onClick={onClick}
    >
      <span className="uppercase weekday">{weekday}</span>
      <IonFabButton
        class="day-dot"
        color={isSelectedDay ? 'primary' : 'white'}
        className="small uppercase"
      >
        <span className="day-number">{number}</span>
      </IonFabButton>

      <DotContainer>{containsSelections && <ContainsSelectionsDot />}</DotContainer>
    </div>
  );
};
