import React, { useState } from 'react';
import { useAtomValue } from 'jotai';
import { multiSelectEnabledAtom, numberOfSelectedSlotsAtom } from '../../logic/multi-select/atoms';
import styled from 'styled-components';
import { multiselectDiscardSelection } from '@/logic/multi-select';

import { Icon } from '@/components/icon';
import CustomAlert from '@/components/custom-alert';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';

const MultiSelectDiscardContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 999;
  padding: 0.5rem 1.6rem;

  ion-button {
    --background: white;
    --border-radius: 5.2rem;
    --color: (var(--grey-grey-11));
    --box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    --padding-bottom: 0.8rem;
    --padding-top: 0.8rem;
    --padding-start: 1.2rem;
    --padding-end: 1.2rem;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin: 0;
    max-height: 3.6rem;
  }
`;

const ButtonInnerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MultiSelectDiscard = () => {
  const [doubleCheckModal, setDoubleCheckModal] = useState(false);
  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const numberOfSelectedSlots = useAtomValue(numberOfSelectedSlotsAtom);
  const { t } = useTranslation('multi-select');

  const openDoubleCheckModal = () => setDoubleCheckModal(true);
  const closeDoubleCheckModal = () => setDoubleCheckModal(false);

  if (!multiSelectEnabled) return null;

  return (
    <>
      <MultiSelectDiscardContainer>
        <IonButton onClick={openDoubleCheckModal}>
          <ButtonInnerContent>
            <Icon name="close" color="var(--base-black)" size="large" />
            {numberOfSelectedSlots}
          </ButtonInnerContent>
        </IonButton>
      </MultiSelectDiscardContainer>
      <CustomAlert
        show={doubleCheckModal}
        title={t('topBarDoubleCheckTitle')}
        text={t('topBarDoubleCheckDescription')}
        confirmButtonText={t('topBarDoubleCheckCTAConfirm')}
        cancelButtonText={t('topBarDoubleCheckCTADiscard')}
        onConfirm={multiselectDiscardSelection}
        onCancel={closeDoubleCheckModal}
      />
    </>
  );
};
