import React from 'react';
import { RenderTabBarProps } from '@/components/tabs';
import styled from 'styled-components';
type StudentProfileTabBarProps = RenderTabBarProps;

const TABS_CONFIG: { label: string }[] = [
  { label: 'Información' },
  { label: 'Documentación' },
  { label: 'Prácticas' },
];
export const StudentProfileTabBar: React.FC<StudentProfileTabBarProps> = ({
  currentTabIndex,
  goToTab,
}) => {
  return (
    <StudentProfileTabBarContainer>
      <div className="tab-labels-wrapper">
        {TABS_CONFIG.map(({ label }, index) => {
          const isActive = currentTabIndex === index;
          return (
            <button
              onClick={() => {
                goToTab(index);
              }}
              key={index}
              className={`${isActive ? 'active' : ''} tab-label-wrapper`}
            >
              <span className="tab-label">{label}</span>
            </button>
          );
        })}
      </div>
    </StudentProfileTabBarContainer>
  );
};

const StudentProfileTabBarContainer = styled.div`
  width: calc(100% - 30px);
  margin: auto;
  background: #f5f7fa;
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 3px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 15px;
  .tab-labels-wrapper {
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .tab-label {
    font-size: max(min(3.7vw, 15px), 14px);
    font-weight: 500;
    transition: all 300ms ease-in-out;
    margin-bottom: 2px;
  }

  .active > .tab-label {
    color: #404040;
  }

  .tab-icon {
    opacity: 0.5;
    transition: all 300ms ease-in-out;
  }

  .active > .tab-icon {
    opacity: 1;
  }
  .tab-label-wrapper {
    color: #707070;
    background: transparent;
    font-weight: 400;
    padding: 5px 12px 5px 12px;
    height: 30px;
    transition: all 300ms ease-in-out;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
  }
  .tab-label-wrapper.active {
    background: white;
    border-radius: 40px;
    box-shadow: 0px 3px 6px #00000029;
  }
`;
