import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonBackButton,
} from '@ionic/react';
import styled from 'styled-components';
import LoadingButton from '../loading-button';

interface ActionLayoutProps {
  title?: string;

  // Add up to two actions that will appear at the bottom of the layout
  actions?: LayoutAction[];

  separator?: boolean;
  renderSubtitle?: () => React.ReactNode;
}

type LayoutAction = {
  disabled?: boolean;
  onClick: () => Promise<void>;
  label: string;
  type: 'primary' | 'secondary';
};

const PADDING_HORIZONTAL = '1.6rem';
const ActionLayoutContainer = styled.div`
  .header-wrapper {
    padding: 0 ${PADDING_HORIZONTAL};
  }

  .header-wrapper > h1 {
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 15px;
  }

  .separator {
    width: 100%;
    height: 1rem;
    min-height: 11px;
    background: var(--grey-grey-3);
  }

  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 2.6rem;
  }

  .content {
    width: 100%;
    flex: 1;
    display: flex;
    padding: ${PADDING_HORIZONTAL};
    padding-bottom: 0.5rem;
  }

  .bottom-actions {
    padding: 1.2rem ${PADDING_HORIZONTAL};
    position: fixed;
    bottom: 0;
    z-index: 99;
    background: white;
    width: 100%;
  }

  .action-button {
    border-radius: 10px;
  }

  .toolbar-button-back {
    height: 2.6rem;
    width: 3rem;
  }
`;
export const ActionLayout: React.FC<ActionLayoutProps> = ({
  children,
  title,
  actions,
  renderSubtitle,
  separator,
}) => {
  const renderTop = () => {
    return (
      <div className="header-wrapper">
        <h1 className="dark">{title}</h1>
        {renderSubtitle ? <div className="subtitle-wrapper">{renderSubtitle()}</div> : null}
      </div>
    );
  };

  const renderActions = () => {
    if (!actions) return <></>;
    return actions.map((action, index) => {
      return (
        <div key={index}>
          <LoadingButton disabled={action.disabled} expand="full" onClick={action.onClick}>
            {action.label}
          </LoadingButton>
        </div>
      );
    });
  };
  return (
    <ActionLayoutContainer>
      <IonPage id="actionLayout">
        <IonHeader>
          <IonToolbar className="toolbar-wrapper">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/calendar" mode="md" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <div className="content-wrapper">
            {renderTop()}
            {separator ? <div className="separator"></div> : null}
            <div className="content">{children}</div>
            {actions ? <div className="bottom-actions">{renderActions()}</div> : null}
          </div>
        </IonContent>
      </IonPage>
    </ActionLayoutContainer>
  );
};
