import React from 'react';
import { clsx } from 'clsx';
import type { BadgeProps } from './badge';
import { getInitials } from '@/logic/user';
import { DriboStudent, DriboTeacher } from '@/types/user';
import type { PracticeUser } from '@/types/practice';
import { Badge } from './badge';
import styled from 'styled-components';

const UserInitials = styled.div`
  color: var(--auxiliares-blue-2);

  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;

  &.x-large {
    font-size: 2.16rem;
    line-height: 2.78rem;
  }

  &.selected {
    color: var(--primary-primary-700);
  }
`;

type UserBadgeProps = {
  user: DriboStudent | DriboTeacher | PracticeUser;
  selected?: boolean;
} & BadgeProps &
  React.HTMLAttributes<HTMLElement>;

export const UserBadge: React.FC<UserBadgeProps> = ({
  user,
  size = 'small',
  style,
  className = '',
  selected = false,
}) => {
  return (
    <Badge
      style={{
        backgroundColor: selected ? 'var(--primary-primary-50)' : 'var(--auxiliares-blue-1)',
        ...style,
      }}
      {...{ className, size }}
    >
      <UserInitials className={clsx(size, selected && 'selected')}>
        {getInitials(user)}
      </UserInitials>
    </Badge>
  );
};
