import React from 'react';
import styled from 'styled-components';
import { DateUtils } from '@/services/date';
import { CalendarHeaderDay } from './calendar-header-day';
import { Icon } from '@/components/icon';
import { SelectAll } from './select-all';
import { useAtomValue } from 'jotai';
import { IonMenuButton } from '@ionic/react';
import { multiSelectEnabledAtom } from '@/logic/multi-select/atoms';

const CalendarHeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: white;
  gap: 0.8rem;

  ion-menu-button {
    position: absolute;
    top: 1rem;
    left: 0.9rem;
    --color: (--base-black);
  }

  ion-fab-button {
    --box-shadow: none;
  }

  .month-title {
    text-align: center;
    color: var(--ion-color-dark-text);
    margin: 0.8rem 0 0 0;
    padding: 0.8rem 0;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
  }

  .arrow {
    color: var(--ion-color-light-text);
    height: 1.5rem;
    width: 2rem;
    margin-top: 1rem;
  }

  .past {
    color: var(--ion-color-light-text);
  }

  .days-selector {
    display: flex;
    padding-bottom: 1.2rem;
    padding: 1.2rem 0;
    justify-content: space-evenly;
    align-items: center;
  }

  .weekday {
    margin-bottom: 0.2rem;

    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.6rem;
  }

  .day-number {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }

  .day-dot {
    width: 2.3rem;
    height: 2.4rem;
    --ion-color-shade: var(--primary-primary-500);
    --ion-color-tint: var(--primary-primary-500) !important;
  }
`;

interface CalendarHeaderProps {
  day: string;
  onDayChange: (day: string) => void;
  className?: string;
}

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({ onDayChange, day }) => {
  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const prevWeek = DateUtils.getPrevWeek(day);
  const nextWeek = DateUtils.getNextWeek(day);

  const dayElements = DateUtils.getWeekInfo(day).map((d) => (
    <CalendarHeaderDay
      key={d.iso}
      selectedDay={day}
      iso={d.iso}
      number={d.number}
      name={d.name}
      onClick={() => onDayChange(d.iso)}
    />
  ));

  return (
    <CalendarHeaderContainer>
      {!multiSelectEnabled && (
        <IonMenuButton color="dark-text">
          <Icon name="menu" size="large" clickable />
        </IonMenuButton>
      )}
      <h1 className="month-title capitalize">
        {DateUtils.getMonthName(day)} {DateUtils.getYear(day)}
      </h1>
      <div className="days-selector">
        <Icon name="chevron_backward" onClick={() => onDayChange(prevWeek)} clickable />
        {dayElements}
        <Icon name="chevron_forward" onClick={() => onDayChange(nextWeek)} clickable />
      </div>
      <SelectAll />
    </CalendarHeaderContainer>
  );
};
