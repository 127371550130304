import React from 'react';
import icons from '../config/material-symbols.json';
import clsx from 'clsx';

export type IconName = keyof typeof icons;

const iconSizes = {
  small: '1.6rem',
  medium: '2rem',
  large: '2.4rem',
};

export type IconSize = keyof typeof iconSizes;

export type IconProps = {
  name: IconName;
  size?: IconSize;
  color?: string;
  fill?: boolean;
  clickable?: boolean;
};

export const Icon: React.FC<IconProps & React.HTMLAttributes<HTMLSpanElement>> = ({
  name,
  size = 'medium',
  fill = false,
  color,
  className = '',
  style = {},
  clickable = false, // TODO: infer onClick? handle keyboard? compose inside button?
  ...rest
}) => (
  <span
    className={clsx('material-symbols-outlined', className)}
    {...(clickable && { role: 'button', tabIndex: 0 })}
    style={{
      minWidth: iconSizes[size],
      minHeight: iconSizes[size],
      fontSize: iconSizes[size],
      color,
      fontVariationSettings: `"FILL" ${fill ? 1 : 0}`,
      ...(clickable && { cursor: 'pointer' }),
      ...style,
    }}
    {...rest}
    dangerouslySetInnerHTML={{
      __html: `&#x${icons[name]};`,
    }}
  />
);
