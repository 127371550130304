import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';

const BackdropEl = styled.div`
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(20, 0, 37);
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);
  pointer-events: none;

  &.visible {
    opacity: 0.4;
    pointer-events: auto;
  }
`;

export const Backdrop: React.FC<{ visible?: boolean; onDiscard?: () => void }> = ({
  visible,
  onDiscard = () => {},
}) => <BackdropEl className={clsx(visible && 'visible')} onClick={onDiscard} />;
