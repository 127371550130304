import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IonSpinner } from '@ionic/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useHistory } from 'react-router-dom';

import { unassignPractice } from '@/services/practices';
import { isMulticar } from '@/services/school';
import notificationsService from '@/services/notifications';
import { DateUtils } from '@/services/date';
import { handleTapped, checkTimeConsistency } from '@/logic/multi-select';
import { multiSelectEnabledAtom } from '@/logic/multi-select/atoms';
import { assignedPracticeToCancelAtom, selectedSlotAtom } from '@/logic/single-action/atoms';
import { loadingAtom, practicesAtom } from '@/logic/calendar/atoms';

import CustomAlert from '@/components/custom-alert';
import { PracticeSlot } from './practice-slot';
import { TimeStripe } from './time-stripe';
import { CalendarBackground } from './calendar-background';
import { CurrentHourIndicator } from './current-hour-indicator';

import type { BookedSlotWithRange } from '@/types/practice';
import type { DriboTeacher } from '@/types/user';

const cellHeight = 88; //px

const PracticeListContainer = styled.div`
  background-color: var(--grey-grey-3);
  top: 13.4rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  padding-top: 1.6rem;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.4s cubic-bezier(0.36, 0.66, 0.04, 1),
    background-color 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);

  &.multi-select-enabled {
    background-color: var(--grey-grey-5);
    transform: translateY(4.2rem);
    .calendar-background,
    .calendar-separator,
    .time-stripe,
    .current-hour-line,
    .current-hour-dot {
      opacity: 0.3;
    }
  }

  .agenda-container {
    display: flex;
    flex-direction: row;
  }

  ion-spinner {
    top: 12%;
  }

  .calendar-separator {
    width: 0.6rem;
    height: 100.3%;
    opacity: 0.4;
    margin-top: -0.8rem;
    border-left: 1px solid var(--grey-grey-8);
  }

  .border-top {
    height: ${cellHeight / 10}rem;
  }

  .border-top::after {
    content: '';
    border-bottom: var(--grey-grey-8) 1px solid;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    z-index: 3;
    pointer-events: none;
    opacity: 0.4;
  }

  .current-hour {
    background-color: var(--grey-grey-10);
    border-radius: 0.3rem;
    padding: 0.2rem 0.4rem;
    margin-left: -0.6rem;
    z-index: 1;
  }

  .current-hour span {
    color: white;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;
  }

  .current-hour-line {
    position: absolute;
    z-index: 9;
    background-color: var(--grey-grey-10);
    left: 0;
    right: 0;
    pointer-events: none;
    height: 2px;
    width: calc(100% + 0.85rem);
    margin-left: -0.85rem;
  }

  .current-hour-dot {
    background: var(--grey-grey-10);
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    margin-left: -0.85rem;
    margin-top: -3px;
    z-index: 9;
  }

  .calendar {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    flex: 1 1 auto;
  }

  .practice-list {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex: 1 1 auto;
  }

  .hour-cell {
    position: relative;
    height: ${cellHeight / 10}rem;
    padding-right: 0.6rem;
    text-align: right;
  }

  .hour {
    display: block;
    position: relative;
    top: -0.8rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
    color: var(--grey-grey-11);
  }

  .quarter-divisions {
    margin-top: 0.3rem;
    margin-bottom: 2rem;
    opacity: 0.4;
  }

  hr {
    background-color: var(--grey-grey-8);
    margin: 0;
    width: 14px;
  }

  hr.half-hour {
    margin: 2.3rem 0;
    width: 1.2rem;
  }

  .teacher-action-sheet {
    padding: 1rem 0;
  }
`;

type PracticeListProps = {
  loggedUser: DriboTeacher;
  day: string;
  car: string;
};

const PracticeList: React.FC<PracticeListProps> = ({ loggedUser, day, car }) => {
  const [currTime, setCurrTime] = useState<string>(DateUtils.getCurrentTimeISO());
  const [multicar, setMulticar] = useState(false);
  const practices = useAtomValue(practicesAtom);
  const loading = useAtomValue(loadingAtom);
  const setSelectedSlot = useSetAtom(selectedSlotAtom);
  const [assignedPracticeToCancel, setAssignedPracticeToCancel] = useAtom(
    assignedPracticeToCancelAtom,
  );
  const history = useHistory();
  const { t } = useTranslation('calendar');

  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrTime(DateUtils.getCurrentTimeISO());
    }, 1000 * 60);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    (async () => {
      setMulticar(await isMulticar(loggedUser.schoolUid, car));
    })();
  }, [car]);

  useEffect(() => {
    checkTimeConsistency();
  }, [currTime]);

  const handleSlotClicked = (practice: BookedSlotWithRange) => {
    if (practice.includedPractices) {
      const includedIds = Object.values(practice.includedPractices).join(',');
      return history.push(`/practice-detail/${includedIds}`);
    }
    history.push(`/practice-detail/${practice.practiceId}`);
  };

  const getTeacherPermissions = () => {
    const { cancel, assign, lock } = loggedUser;
    return { cancel, assign, lock };
  };

  const handleDotsClicked = (practice: BookedSlotWithRange) => {
    setSelectedSlot(practice);
  };

  const cancelAssignedPractice = (practice?: BookedSlotWithRange) => {
    const toastId = notificationsService.showLoadingToast(
      t('notifications.cancel-assignment.start'),
    );
    if (!practice || !practice.user || !practice.practiceId) return;
    return unassignPractice(loggedUser.schoolUid, car, practice.user.uid, practice.practiceId)
      .then(() => {
        notificationsService.updateLoadingToast(
          toastId,
          'success',
          t('notifications.cancel-assignment.success'),
        );
      })
      .catch(() => {
        notificationsService.updateLoadingToast(
          toastId,
          'error',
          t('notifications.cancel-assignment.error'),
        );
      });
  };

  const currMinutes = DateUtils.getMinutes(currTime);
  const currHour = DateUtils.getHour(currTime);
  const isToday = DateUtils.isToday(day);
  const hourIndicatorPos = (cellHeight * currMinutes) / 60 + (currHour - 6) * cellHeight;

  return (
    <PracticeListContainer className={clsx(multiSelectEnabled && 'multi-select-enabled')}>
      <div className="agenda-container">
        <TimeStripe isToday={isToday} currTime={currTime} hourIndicatorPos={hourIndicatorPos} />

        <div className="practice-list">
          <CalendarBackground />
          {loading && <IonSpinner name="dots" />}

          {!loading && (
            <div className="relative width-100 height-100">
              {isToday && <CurrentHourIndicator {...{ hourIndicatorPos }} />}

              <div className="relative height-100 width-100">
                {practices.map((practice) => (
                  <PracticeSlot
                    key={practice.__front_uid__}
                    practice={practice}
                    cellHeight={cellHeight}
                    currentDay={day}
                    teacherPermissions={getTeacherPermissions()}
                    isMulticar={multicar}
                    onDotsClicked={() => handleDotsClicked(practice)}
                    onSlotClicked={() => {
                      if (multiSelectEnabled) {
                        handleTapped(practice);
                      } else {
                        practice.practiceId && handleSlotClicked(practice);
                      }
                    }}
                    onLongPress={() => {
                      handleTapped(practice);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <>
          {assignedPracticeToCancel ? (
            <CustomAlert
              show={assignedPracticeToCancel ? true : false}
              onConfirm={async () => {
                setAssignedPracticeToCancel(null);
                await cancelAssignedPractice(assignedPracticeToCancel);
              }}
              onCancel={() => {
                // FIXME: review if this behavior is correct for discarded modal
                setAssignedPracticeToCancel(null);
              }}
              title={t('cancelAssignedPracticeModalTitle')}
              text={t('cancelAssignedPracticeModalText')}
              user={assignedPracticeToCancel.user as any}
              confirmButtonText={t('cancelAssignedPracticeModalCTA')}
              cancelButtonText={t('cancelAssignedPracticeModalCancel')}
              practice={
                {
                  practice: {
                    date: assignedPracticeToCancel.date,
                    start: assignedPracticeToCancel.start,
                    end: assignedPracticeToCancel.end,
                  },
                } as any
              }
            />
          ) : null}
        </>
      </div>
    </PracticeListContainer>
  );
};

export default PracticeList;
