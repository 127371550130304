const development: AppConfig = {
  firebase: {
    apiKey: 'AIzaSyA2vGFuonUzCK2iVEDAsvFPBo2GXTe2BOc',
    authDomain: 'dribodev-firestore.firebaseapp.com',
    databaseURL: 'https://dribodev-firestore.firebaseio.com',
    projectId: 'dribodev-firestore',
    storageBucket: 'dribodev-firestore.appspot.com',
    messagingSenderId: '225827267797'
  },
  functions: 'https://us-central1-dribodev-firestore.cloudfunctions.net',
  credentials: {
    email: 'amigobeer@mailinator.com',
    password: 'amigobeer'
  },
  graphQL: 'https://dribo-api-graphql-nf-dev-225827267797.europe-southwest1.run.app',
  i18n: {
    debug: true
  },
  messagebird: {
    id: '8f4d1600-b016-4046-b529-9b8c44e8fa3a'
  },
  intercom: {
    appId: '8f4d1600-b016-4046-b529-9b8c44e8fa3a'
  }
};

export default development;

export interface AppConfig {
  firebase: FirebaseConfig;
  credentials?: DevCredentials;
  functions: string;
  graphQL: string;
  i18n?: i18nConfig;
  messagebird: MessagebirdConfig;
  intercom: any;
}

type MessagebirdConfig = {
  id: string;
};

type i18nConfig = {
  debug?: boolean;
};

type DevCredentials = {
  email: string;
  password: string;
};

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}
