import { atom } from 'jotai';

import { titlerize } from '@/utils';
import { DriboTeacher } from '@/types/user';
import { DriboStudent } from '@/types/user';
import { PracticeUser } from '@/types/practice';

export const userAtom = atom<DriboTeacher | null>(null);

// TODO: duplicates userService's fullName
export const getFullName = (user: DriboTeacher | DriboStudent | PracticeUser) => {
  const fullName = ['name', 'lastName', 'lastName2']
    .map((key) => (key in user ? user[key as keyof typeof user] : ''))
    .filter((value) => !!value)
    .join(' ');

  return titlerize(fullName);
};

// TODO: duplicates setInitials
export const getInitials = (user: DriboTeacher | DriboStudent | PracticeUser) => {
  if (!user || !user.email) return '-';
  if (!user.name) return user.email[0].toUpperCase() || '-';
  if ('lastName' in user)
    return [user.name[0] || '', user.lastName?.[0] || ''].join('').toUpperCase();
  return user.name[0].toUpperCase() || '-';
};
