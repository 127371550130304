import type { BookedSlotWithRange } from '@/types/practice';
import { getDefaultStore } from 'jotai';
import { getSlotStatus, isNotMultiselectable } from '../slot';
import {
  numberOfSelectedSlotsAtom,
  selectedSlotsIdAtom,
  selectedSlotsStatusAtom,
  selectedStudentAtom,
  selectionAtom,
} from './atoms';

const store = getDefaultStore();

const __selectionContains = (slot: BookedSlotWithRange): boolean => {
  const selection = store.get(selectionAtom);
  return selection.some((existingSlot) => existingSlot.__front_uid__ === slot.__front_uid__);
};

const __slotStatusDoesNotMatchSelection = (slot: BookedSlotWithRange) => {
  const selectedSlotsStatus = store.get(selectedSlotsStatusAtom);
  return getSlotStatus(slot) !== selectedSlotsStatus;
};

const __resetStateAtoms = () => {
  store.set(selectedSlotsStatusAtom, undefined);
  store.set(selectedStudentAtom, undefined);
};

const __addSlot = (slot: BookedSlotWithRange) => {
  store.set(selectionAtom, (selection) => [...selection, slot]);
};

export const checkTimeConsistency = () => {
  const selection = store.get(selectionAtom);

  const slotsWhoseStatusHasChanged = selection.filter(__slotStatusDoesNotMatchSelection);

  if (slotsWhoseStatusHasChanged.length) {
    removeSlotsFromSelection(slotsWhoseStatusHasChanged);
  }
};

export const multiselectBackendUpdateConsistencyCheck = (newSlots: BookedSlotWithRange[]) => {
  const selectedSlotsId = store.get(selectedSlotsIdAtom);

  const selectionIntersection = newSlots.filter((slot) =>
    selectedSlotsId.includes(slot.__front_uid__),
  );

  const slotsWhoseStatusHasChanged = selectionIntersection.filter(
    __slotStatusDoesNotMatchSelection,
  );

  if (slotsWhoseStatusHasChanged.length) {
    removeSlotsFromSelection(slotsWhoseStatusHasChanged);
  }
};

export const removeSlot = (slot: BookedSlotWithRange) => {
  const numberOfSelectedSlots = store.get(numberOfSelectedSlotsAtom);

  store.set(selectionAtom, (selection) =>
    selection.filter((existingSlot) => existingSlot.__front_uid__ !== slot.__front_uid__),
  );
  if (numberOfSelectedSlots === 1) __resetStateAtoms();
};

export const addSlotsToSelection = (slots: BookedSlotWithRange[]) => {
  const selectedSlotsId = store.get(selectedSlotsIdAtom);

  const newSlots = slots.filter((slot) => !selectedSlotsId.includes(slot.__front_uid__));
  if (newSlots.some(__slotStatusDoesNotMatchSelection)) {
    throw new Error('Attempting to add slots with different type to selection');
  }
  store.set(selectionAtom, (selection) => [...selection, ...newSlots]);
};

export const removeSlotsFromSelection = (slots: BookedSlotWithRange[]) => {
  const numberOfSelectedSlots = store.get(numberOfSelectedSlotsAtom);

  const slotToRemoveIds = slots.map((slot) => slot.__front_uid__);
  if (numberOfSelectedSlots === slotToRemoveIds.length) __resetStateAtoms();
  store.set(selectionAtom, (selection) =>
    selection.filter((slot) => !slotToRemoveIds.includes(slot.__front_uid__)),
  );
};

export const multiselectDiscardSelection = () => {
  store.set(selectionAtom, []);
  __resetStateAtoms();
};

export const handleTapped = (slot: BookedSlotWithRange) => {
  const numberOfSelectedSlots = store.get(numberOfSelectedSlotsAtom);

  const status = getSlotStatus(slot);
  if (isNotMultiselectable(status)) return;

  if (__selectionContains(slot)) {
    removeSlot(slot);
    if (numberOfSelectedSlots === 1) __resetStateAtoms();
    return;
  }

  if (numberOfSelectedSlots === 0) {
    store.set(selectedSlotsStatusAtom, status);
    __addSlot(slot);
  } else {
    const selectedSlotsStatus = store.get(selectedSlotsStatusAtom);
    if (status === selectedSlotsStatus) __addSlot(slot);
  }
};
