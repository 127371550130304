import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonSpinner,
  IonCheckbox,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonLabel,
  IonItem,
  IonIcon,
} from '@ionic/react';
import userService from '../services/user';
import { DateUtils } from '../services/date';
import schoolService from '../services/school';
import Avatar from '../components/avatar';
import ContactButtons from '../components/contact-buttons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PrivateRouteNavigationProps } from '../components/private-route';
import { DriboStudent } from '../types/user';
import { StudentProfileTabs } from '@/components/student-profile/student-profile-tabs/student-profile.tabs';
import { StudentPracticesList } from '@/components/student-practices-list';
import WarningMark from '@/components/warning-mark';
import { description } from '../assets/icons/index';
import { Browser } from '@capacitor/browser';
import { getSignedUrl } from '@/services/firebase-adapter';

const UserProfilePageContainer = styled.div`
  height: 100%;
  display: flex;
  ion-spinner {
    position: absolute;
  }

  .content-header {
    margin: 0 auto;
    height: 15rem;
    tex-align: center;
    align-items: center;
  }

  .user-name {
    margin: 1.2rem auto;
  }

  ion-avatar {
    margin-right: 0 !important;
  }

  ion-item.list-item {
    --padding-start: 0;
    --padding-end: 0;
    --min-height: 1rem;
  }

  ion-item {
    --padding-start: 2rem;
    --padding-end: 2rem;
    --border-color: transparent;
  }

  ion-label {
    margin: 0;
  }

  .item .small-paragraph-text {
    --color: var(--ion-color-dark-text);
    color: var(--ion-color-dark-text);
  }

  ion-list-header {
    margin-top: 3rem;
    min-height: 3rem;
    height: 3rem;
    line-height: 3rem;
    margin-bottom: 0;
    padding-left: 2rem;
  }

  ion-item.first {
    --inner-border-width: 1px 0 1px 0;
  }

  ion-checkbox {
    margin: 0;
  }

  .tabs-container {
    flex: 1;
  }

  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .tab-wrapper {
    margin-top: 1.5rem;
    padding-bottom: 8.5rem;
  }

  .practices-count-text {
    text-align: left;
    margin-left: 20px;
    font-size: 16px;
    color: #707070;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .pending-pill {
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 22px;
    background-color: #ffeeed;
  }

  .pending-pill > .label {
    color: #ff5345;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 1px;
  }

  .pending-pill .warning-mark {
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    font-size: 1rem;
    margin-right: 5px;
    padding-left: 0.5px;
  }

  .warning-mark-wrapper {
    position: absolute;
    bottom: -4px;
    right: -4px;
  }

  .warning-mark-wrapper .warning-mark {
    border: 3px solid white;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 600;
  }

  .student-avatar > ion-avatar {
    margin: auto !important;
  }

  .document-row {
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 2.8rem;
  }

  .document-row > ion-item {
    flex: 1;
  }

  .document-download {
    --background: #f2e7ff;
    margin-right: 1.6rem;
    border-radius: 1000px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #eee5ff;
  }

  .document-download .button-native {
    padding-inline-start: 0 !important;
    padding-inline-end: 0;
  }

  .document-icon {
    font-size: 20px;
  }

  .document-download.disabled {
    background: #e8e8e8;
  }

  .document-download.disabled > .document-icon {
    color: #7e7e7e;
  }

  .separator {
    background: #f7f8fd;
    width: 100%;
    height: 1.4rem;
    margin-bottom: 2.8rem;
  }

  .talon-check {
    --min-height: 1px;
    overflow: visible;
  }

  .talon-check > ion-label {
    overflow: visible;
  }
`;

type UserProfilePageProps = PrivateRouteNavigationProps;
const UserProfilePage: React.FC<UserProfilePageProps> = (props) => {
  const initialDocs = {
    talon: {},
    cm: { disabled: true },
    examApplication: { disabled: true },
    contract: { disabled: true },
    traspaso: { disabled: true },
  };
  const { uid } = useParams<{ uid: string }>();
  const { loggedUser, history } = props;
  const [student, setStudent] = useState<DriboStudent>();
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState<Record<string, any>>(initialDocs);
  const [documents, setDocuments] = useState<Record<string, any>>();
  const [studentPractices, setStudentPractices] = useState<any[]>([]);
  const { t } = useTranslation('student-profile');

  const fields: { [key: string]: { type?: 'ts' } } = {
    dni: {},
    phone: {},
    numMatricula: {},
    practicesWithSchool: {},
    numAttempts: {},
    numPracticeExams: {},
    lastPracticeExam: { type: 'ts' },
    theoryExpires: { type: 'ts' },
  };

  useEffect(() => {
    if (!loggedUser) return history.push('/login');
    getUserData();
  }, []);

  const removeUnneededDocs = (student: DriboStudent) => {
    const docs = userService.removeUnneededDocs(student.practiceUserType, initialDocs);
    setDocs(docs);
  };

  const getUserData = () => {
    userService.fetchStudentProfile(uid).then((student) => {
      setStudent(student);
      setLoading(false);
      removeUnneededDocs(student);
      schoolService.fetchDocuments(uid, loggedUser.schoolUid).then((documents) => {
        const docs = documents;
        setDocuments(docs);
        schoolService.getPastUserPractices(student.school!, student.uid).then(setStudentPractices);
      });
    });
  };

  const toggleDocumentReceived = (doctype: string, checked: boolean) => {
    if (student) {
      const { uid } = student;
      const { loggedUser } = props;
      const status = checked ? 'VALID' : 'INVALID';
      let validDoc = {};
      if (documents) {
        validDoc = { [doctype]: { valid: checked } };
      }
      setDocuments({ ...documents, ...validDoc });
      schoolService.pushDocumentChange(
        uid,
        loggedUser.schoolUid,
        doctype,
        schoolService.historyType[status],
        loggedUser.name,
      );
    }
  };

  const renderField = (field: { type?: string }, value: string | number) => {
    if (!field.type) return value;
    if (field.type === 'ts') return DateUtils.fromTsToLocal(value as number);
    return value;
  };

  const renderPersonalInfo = () => {
    return (
      <div className="tab-wrapper">
        {Object.keys(fields).map((field, i) => {
          return (
            <IonItem className={'personalInfo'} key={field}>
              <IonLabel className="small-paragraph-text">
                {t(field)}:{' '}
                <span className="light">{renderField(fields[field], (student as any)[field])}</span>
              </IonLabel>
            </IonItem>
          );
        })}
      </div>
    );
  };

  const openDocument = async (document: Record<string, any>, docType: string) => {
    let doc: string | undefined;
    if (document.docs) {
      Object.keys(document.docs).forEach((_key) => {
        const key = _key.toString();
        if (doc) return;
        if (docType === 'cm' && key == '0') {
          doc = document.docs[key];
        } else {
          if (key !== '0' && key !== '1' && key !== 'pdf') {
            doc = document.docs[key];
          }
        }
      });
      if (!doc) return;
      const url = await getSignedUrl(doc);
      if (!url) return;
      await Browser.open({ url });
    }
  };

  const renderDocumentation = () => {
    return (
      <div className="tab-wrapper">
        <div className="document-list">
          {Object.keys(docs).map((field, i) => {
            if (field === 'talon') return <React.Fragment key={i}></React.Fragment>;
            const isValid = documents && documents[field] && documents[field].valid;

            const hasDownloadableDoc =
              documents &&
              documents[field] &&
              documents[field].docs &&
              (documents[field].docs[0] || documents[field].docs['pdf']) &&
              isValid;
            return (
              <div className="document-row" key={i}>
                <a
                  className={`document-download ${!hasDownloadableDoc ? 'disabled' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDocument(documents![field], field);
                  }}
                >
                  <IonIcon className="document-icon" src={description} />
                </a>

                <IonItem
                  key={field}
                  className={`${hasDownloadableDoc ? 'with-doc' : ''} list-item`}
                >
                  <IonLabel className="small-paragraph-text dark">{t(field)}</IonLabel>
                  {!isValid ? (
                    <span className="pending-pill">
                      <WarningMark />
                      <span className="label">{t('pendingDocs')}</span>
                    </span>
                  ) : null}
                </IonItem>
              </div>
            );
          })}
        </div>
        <div className="separator"></div>
        <IonItem className="talon-check">
          <IonLabel className="small-paragraph-text dark">{t('talon')}</IonLabel>
          <IonCheckbox
            mode="md"
            onClick={(evt) => {
              // The types are wrong, property checked exists on target, but OK Ionic
              // This weird recast is to fix the types
              const target = (evt.target as unknown) as { checked: boolean };
              toggleDocumentReceived('talon', target.checked);
            }}
            checked={documents && documents['talon'] && documents['talon'].valid}
          />
        </IonItem>
      </div>
    );
  };

  const renderPractices = () => {
    return (
      <IonContent>
        <div className="tab-wrapper">
          <p className="practices-count-text">Prácticas realizadas: {studentPractices.length}</p>
          <StudentPracticesList
            studentPractices={studentPractices}
            reverse
            shouldNavigateToDetail
          />
        </div>
      </IonContent>
    );
  };
  if (!student) return <></>;
  return (
    <IonPage id="student-profile">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <UserProfilePageContainer>
          {!loading && (
            <div className="content-container">
              <div className="content-header flex-vertical">
                <div className="relative">
                  <Avatar className="x-large student-avatar" user={student} />
                  {documents && schoolService.hasPendingDocs(documents) ? (
                    <div className="warning-mark-wrapper">
                      <WarningMark />
                    </div>
                  ) : null}
                </div>
                <span className="user-name center main-text-large semibold">
                  {userService.fullName(student)}
                </span>

                <ContactButtons
                  sendWhatsapp
                  className="flex-horizontal justify-center"
                  student={student}
                />
              </div>
              <div className="tabs-container">
                <StudentProfileTabs
                  personalInfoTab={renderPersonalInfo}
                  documentationTab={renderDocumentation}
                  practicesTab={renderPractices}
                />
              </div>
            </div>
          )}
          {loading && <IonSpinner name="dots" />}
        </UserProfilePageContainer>
      </IonContent>
    </IonPage>
  );
};

export default UserProfilePage;
