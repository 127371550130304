import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DateUtils } from '@/services/date';
import { SlotBadge } from '@/components/badge/slot-badge';
import { Icon } from '@/components/icon';
import { getSlotStatus, SlotStatus } from '@/logic/slot';
import { getFullName } from '@/logic/user';
import type { BookedSlotWithRange } from '@/types/practice';

const SummaryItemContainer = styled.li`
  display: flex;
  padding: 0.8rem 0;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 1.2rem;

  .text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.4rem;
  }

  .text .description {
    color: var(--base-black);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.8rem;
  }

  .text .date-time {
    display: flex;
    gap: 1.6rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--grey-grey-8);
  }

  .date,
  .time {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
`;

type SummaryItemProps = {
  slot: BookedSlotWithRange;
  onDelete?: () => void;
};

export const SummaryItem: React.FC<SummaryItemProps> = ({ slot, onDelete = () => {} }) => {
  const { t } = useTranslation('multi-select');

  const slotStatus = useMemo(() => getSlotStatus(slot), [slot]);

  const slotDescription = useMemo(() => {
    if ([SlotStatus.FREE, SlotStatus.PAST_FREE, SlotStatus.LOCKED].includes(slotStatus)) {
      return t(`statuses.${slotStatus}`);
    } else {
      return slot.user && getFullName(slot.user);
    }
  }, [slot, slotStatus]);

  return (
    <SummaryItemContainer>
      <SlotBadge slot={slot} />
      <div className="text">
        <div className="description">{slotDescription}</div>
        <div className="date-time">
          <div className="date">
            <Icon name="calendar_today" size="small" />
            {DateUtils.fromISOToLocal(slot.date)}
          </div>
          <div className="time">
            <Icon name="schedule" size="small" />
            {slot.start} {t('to')} {slot.end}
          </div>
        </div>
      </div>
      <Icon name="delete" size="large" color="var(--grey-grey-8)" onClick={onDelete} clickable />
    </SummaryItemContainer>
  );
};
