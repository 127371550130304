import React from 'react';

export const CurrentHourIndicator: React.FC<{ hourIndicatorPos: number }> = ({
  hourIndicatorPos,
}) => (
  <>
    <hr className="current-hour-line" style={{ top: hourIndicatorPos - 1 + 'px' }} />
    <div className="current-hour-dot" style={{ top: hourIndicatorPos - 1 + 'px' }} />
  </>
);
