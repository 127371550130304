import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

const CollapsibleListContainer = styled.section`
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    transition: height 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);
    overflow: hidden;
    margin-block-start: 0.8rem;
    margin-block-end: 0.2rem;
  }

  button {
    width: 100%;
    background: white;
    border: none;
    padding-top: 1.6rem;
    padding-bottom: 2.4rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    color: var(--primary-primary-500);
  }
`;

type CollapsibleListProps = { show: number; itemHeightRem: number };

export const CollapsibleList: React.FC<React.PropsWithChildren<CollapsibleListProps>> = ({
  children,
  show,
  itemHeightRem,
}) => {
  const [displayAll, setDisplayAll] = useState(false);

  const hasNotVisbleChildren = show < React.Children.toArray(children).length;
  const numberOfVisibleItems = useMemo(
    () => (!displayAll && hasNotVisbleChildren ? show : React.Children.toArray(children).length),
    [displayAll, children],
  );

  //TODO: requires height, try using boundingClientRect (inefficient) or type
  const heightRem = useMemo(() => numberOfVisibleItems * itemHeightRem, [numberOfVisibleItems]);

  const handleToggle = () => setDisplayAll((displayAll) => !displayAll);
  const numberOfExtraChild = hasNotVisbleChildren
    ? React.Children.toArray(children).length - show
    : 0;
  const actionText = displayAll ? 'Ver menos' : `Ver ${numberOfExtraChild} más`;

  return (
    <>
      <CollapsibleListContainer>
        <ul style={{ height: `${heightRem}rem` }}>{children}</ul>
        {hasNotVisbleChildren && <button onClick={handleToggle}>{actionText}</button>}
      </CollapsibleListContainer>
    </>
  );
};
