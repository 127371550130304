import React from 'react';
import { toast, Slide, Zoom, Flip, Bounce, ToastOptions, TypeOptions } from 'react-toastify';
import type { IconName } from '@/components/icon';
import { Icon } from '@/components/icon';

export const POSITIONS = toast.POSITION;

export const TRANSITIONS = { Slide, Zoom, Flip, Bounce };

const TEMPLATE_TOAST_CONFIG: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: 0,
  transition: Slide,
  closeButton: () => <Icon name="close" color="var(--base-black)" />,
  icon: () => <Icon name="check_circle" size="large" color="var(--notificaciones-green)" />,
};

const getToastOptions = (options?: ToastOptions) => {
  if (!options) return TEMPLATE_TOAST_CONFIG;
  //We pass icon url so we have to create the ReactNode
  if (options.icon && typeof options.icon === 'string') {
    const icon = options.icon as IconName;
    options.icon = () => (
      <div className="toast-info-icon circle toast-icon-bg">
        <Icon name={icon} />
      </div>
    );
  }
  return Object.assign({}, TEMPLATE_TOAST_CONFIG, options);
};

export const showBasicToast = (text: string, options?: ToastOptions) => {
  const toastOptions = getToastOptions(options);
  return toast(text, toastOptions);
};

export const showErrorToast = (text: string, options?: ToastOptions) => {
  const toastOptions = getToastOptions(options);
  toastOptions.icon = () => <Icon name="error" size="large" color="var(--notificaciones-red)" />;
  return toast(text, toastOptions);
};

export const showLoadingToast = (text: string, options?: ToastOptions) => {
  const toastOptions = getToastOptions(options);
  delete toastOptions.icon;
  return toast.loading(text, toastOptions);
};

export const updateLoadingToast = (toastId: React.ReactText, type: TypeOptions, text: string) => {
  return toast.update(toastId, {
    render: text,
    type: type,
    isLoading: false,
    icon: () => {
      const isSuccess = type === 'success';
      const name: IconName = isSuccess ? 'check_circle' : 'error';
      const color = isSuccess ? 'var(--notificaciones-green)' : 'var(--notificaciones-red)';
      return <Icon name={name} size="large" color={color} />;
    },
    autoClose: 3000,
  });
};

export const closeToast = (toastId: React.ReactText) => {
  return toast.dismiss(toastId);
};

export default {
  POSITIONS,
  TRANSITIONS,
  showBasicToast,
  showErrorToast,
  showLoadingToast,
  updateLoadingToast,
  closeToast,
};
