import clsx from 'clsx';
import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export type BadgeProps = {
  size?: 'small' | 'medium' | 'large' | 'x-large';
};

const BadgeContainer = styled.figure`
  &.badge {
    margin: 0;
    display: flex;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &--medium {
      width: 4.4rem;
      height: 4.4rem;
    }

    &--large {
      width: 4.8rem;
      height: 4.8rem;
    }

    &--x-large {
      width: 6.8rem;
      height: 6.8rem;
    }
  }
`;

export const Badge: FC<PropsWithChildren<BadgeProps> & React.HTMLAttributes<HTMLElement>> = ({
  size = 'small',
  children,
  className = '',
  ...rest
}) => {
  return (
    <BadgeContainer className={clsx('badge', `badge--${size}`, className)} {...rest}>
      {children}
    </BadgeContainer>
  );
};
