import { titlerize } from '../utils';
import { DriboCar } from '../types/car';
import { DriboPractice, Checkpoint } from '../types/practice';
import { DriboDocumentation, DriboStudent, DriboTeacher } from '../types/user';
import firebaseService from './firebase-adapter';
import storage from './storage';
const self = {};

export const historyType = {
  NOT_RECEIVED: 'not_received',
  RECEIVED: 'received',
  SENT: 'sent',
  VIEWED: 'viewed',
  INVALID: 'invalid',
  VALID: 'valid',
  GENERATED: 'generated',
};

export const fetchDocuments = (userId: string, schoolId: string) =>
  firebaseService.databaseGet<DriboDocumentation>(
    `schoolUserInfo/${userId}-${schoolId}/documentation`,
  );

export const pushDocumentChange = (
  userId: string,
  schoolId: string,
  documentId: string,
  type: string,
  author: string,
) => {
  return firebaseService.databasePush(
    `schoolUserInfo/${userId}-${schoolId}/documentationHistory/${documentId}`,
    {
      message: `Marcado desde app teachers (${author})`,
      type,
      timestamp: Date.now(),
    },
  );
};

export const fetchCarsForTeacher = (schoolId: string, teacherId: string) => {
  return fetchCars(schoolId).then((cars) => {
    return Object.values(cars).filter((car) => Object.keys(car.teachers || {}).includes(teacherId));
  });
};

export const fetchCars = (schoolUid: string) =>
  firebaseService.databaseGet<{ [key: string]: DriboCar }>(`schools/${schoolUid}/cars`);

export const isMulticar = async (schoolId: string | undefined, carId: string | undefined) => {
  if (!schoolId || !carId) return false;
  const carType: string = await firebaseService.databaseGet<string>(
    `schools/${schoolId}/cars/${carId}/carType`,
  );
  return carType === 'multicar';
};

export const fetchCheckpoints = async (schoolId: string) => {
  return firebaseService.databaseGet<{ [key: string]: Checkpoint }>(
    `schools/${schoolId}/checkpoints`,
  );
};

export const getPracticeStudents = () => {
  return firebaseService.callFunction<DriboStudent[]>('schoolData-getPracticeStudents');
};

export const fetchUserPractices = (schoolId: string, userId: string) => {
  return firebaseService.databaseQueryByChild<DriboPractice>(
    `schoolPractices/${schoolId}`,
    'user',
    userId,
  );
};

export const getPastUserPractices = (schoolId: string, userId: string) => {
  return fetchUserPractices(schoolId, userId).then((res) => {
    return Object.keys(res || {})
      .map<any>((p) => Object.assign({ pid: p }, (res as any)[p]))
      .filter((p) => p.timestamp < Date.now())
      .sort((p1, p2) => {
        const time1 = p1.practice.timestamp;
        const time2 = p2.practice.timestamp;
        if (time1 > time2) return 1;
        else return -1;
      });
  });
};

const STUDENTS_LIST_KEY = '@students';
export const initStudentsList = async () => {
  const saveStudents = async () => {
    const students = await getPracticeStudents();
    if (students) {
      await storage.write(STUDENTS_LIST_KEY, students);
    }

    return students;
  };

  // First check localStorage to see if we have an students list copy;
  const storedStudents = await storage.read<DriboStudent[]>(STUDENTS_LIST_KEY);

  if (storedStudents)
    return {
      isFromStorage: true,
      students: storedStudents,
      refreshStorage: saveStudents,
    };

  // We don't have an students list stored, fetch and store
  return {
    isFromStorage: false,
    students: await saveStudents(),
  };
};

export const hasPendingDocs = (documents: Record<string, any>) => {
  return Object.values(documents).some((doc) => !doc.valid);
};

export const getStudentName = (student: DriboStudent) => {
  return titlerize([student.name, student.lastName, student.lastName2].filter((i) => i).join(' '));
};

export default {
  initStudentsList,
  getPastUserPractices,
  fetchUserPractices,
  fetchCars,
  getPracticeStudents,
  fetchCarsForTeacher,
  historyType,
  fetchDocuments,
  pushDocumentChange,
  hasPendingDocs,
};
