import React, { useContext } from 'react';
import { DriboTeacher } from '../types/user';

export type AuthContextShape = {
  loggedUser: null | DriboTeacher;
};
const INIT: AuthContextShape = {
  loggedUser: null,
};
export const AuthContext = React.createContext<AuthContextShape>(INIT);

interface AuthProviderProps {
  user: DriboTeacher | null;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children, user }) => {
  return <AuthContext.Provider value={{ loggedUser: user }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
