import React, { useEffect } from 'react';
import PracticeList from './practice-list/practice-list';
import { CalendarHeader } from './calendar-header/calendar-header';
import { PrivateRouteNavigationProps } from '@/components/private-route';

import { IonPage, IonContent } from '@ionic/react';

import { useDribo } from '@/contexts/driboContext';
import { MultiSelectDiscard } from './multi-select-discard';
import { BottomActions } from './bottom-actions';
import { useAtom } from 'jotai';
import { dayAtom } from '@/logic/calendar/atoms';
import { initialise, destroy } from '@/logic/calendar';

export const CalendarPage: React.FC<PrivateRouteNavigationProps> = ({ loggedUser }) => {
  const [currentDay, setCurrentDay] = useAtom(dayAtom);
  const { selectedCar } = useDribo();

  useEffect(() => {
    initialise();

    return () => {
      destroy();
    };
  }, []);

  if (!loggedUser) return null;

  return (
    <IonPage id="calendar">
      <MultiSelectDiscard />
      <IonContent>
        <CalendarHeader day={currentDay} onDayChange={setCurrentDay} />
        <PracticeList day={currentDay} loggedUser={loggedUser} car={selectedCar || ''} />
      </IonContent>
      <BottomActions />
    </IonPage>
  );
};

export default CalendarPage;
