import type { BookedSlotWithRange } from '@/types/practice';
import { atom } from 'jotai';

export const loadingAtom = atom(true);

export const dayAtom = atom<string>('');

export const practicesMapAtom = atom<Record<string, BookedSlotWithRange[]>>({});

export const practicesAtom = atom((get) => {
  const day = get(dayAtom);
  const practicesMap = get(practicesMapAtom);

  return practicesMap[day] || [];
});
