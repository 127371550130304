import React from 'react';
import { useAuth } from '../contexts/authContext';
import { Route, Redirect } from 'react-router-dom';
import { RouteComponentProps, StaticContext, RouteProps } from 'react-router';
import { DriboTeacher } from '../types/user';

export type PrivateRouteNavigationProps<T = Record<string, string>> = RouteComponentProps<
  T,
  StaticContext,
  unknown
> & {
  loggedUser: DriboTeacher;
};

export interface PrivateRouteProps extends Omit<RouteProps, 'render'> {
  render: (props: PrivateRouteNavigationProps) => React.ReactNode;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ render, ...rest }) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.loggedUser ? (
          render({ ...props, loggedUser: auth.loggedUser })
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: rest.location },
            }}
          />
        )
      }
    />
  );
};
