import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { CollapsibleList } from '@/components/collapsible-list';
import { PrivateRouteNavigationProps } from '@/components/private-route';
import { removeSlot, multiselectDiscardSelection } from '@/logic/multi-select';
import { SlotAction } from '@/logic/slot';
import { SummaryItem } from './summary-item';
import { CancelReasons } from './cancel-reasons';
import { BlockReasons } from './block-reasons';
import { StudentPicker } from './student-picker';
import {
  numberOfSelectedSlotsAtom,
  selectedActionAtom,
  selectedStudentAtom,
  selectionAtom,
} from '@/logic/multi-select/atoms';
import { useDribo } from '@/contexts/driboContext';
import { handleMultiAction } from '@/logic/multi-select/handle-multi-action';
import { InfoBox } from '@/components/info-box';
import assignImg from '@/assets/img/review-info-box/assign.png';
import cancelImg from '@/assets/img/review-info-box/cancel.png';
import blockImg from '@/assets/img/review-info-box/block.png';
import type { IonicScrollDetail } from '@/types/ionic';
import { SafeInsetBottom } from '@/components/spacing/safe-inset-bottom';

const ReviewPageContainer = styled.article`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 0.3rem);

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 3.2rem;
    margin-bottom: 0.8rem;
  }

  > header,
  > section,
  > footer {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  > header p {
    margin: 1.6rem 0 0.8rem;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem;
  }

  .separator {
    width: 100%;
    height: 1rem;
    background: var(--grey-grey-3);
  }

  > .bottom-box {
    margin-top: auto;
    padding-bottom: 8rem;
  }

  footer {
    z-index: 10;
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1.2rem 1.6rem;
  }

  footer ion-button {
    width: 100%;
    height: 4.8rem;
    --background: var(--primary-primary-500);
    --color: white;
    --border-radius: 2.4rem;
    --box-shadow: none;
    --padding-top: 1.2rem;
    --padding-bottom: 1.2rem;
    --padding-start: 2.4rem;
    --padding-end: 2.4rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.4rem;
  }

  footer ion-button[disabled] {
    --background: var(--grey-grey-4);
    --color: var(--grey-grey-9);
  }
`;

const TopBarHeader = styled.h1`
  margin-left: 1.2rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--base-black);
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);
  display: none;

  &.not-loading {
    display: block;
  }

  &.display {
    opacity: 1;
  }
`;

const TOPBAR_HEADER_DISPLAY_THRESHOLD = 29;

type ReviewPageProps = PrivateRouteNavigationProps;

const ReviewPage: React.FC<ReviewPageProps> = ({ loggedUser }) => {
  const [loading, setLoading] = useState(true);
  const [displayTopBarHeader, setDisplayTopBarHeader] = useState(false);
  const { selectedCar } = useDribo();

  const selectedAction = useAtomValue(selectedActionAtom);
  const selectedSlots = useAtomValue(selectionAtom);
  const numberOfSelectedSlots = useAtomValue(numberOfSelectedSlotsAtom);
  const selectedStudent = useAtomValue(selectedStudentAtom);
  const [selectedCancelReason, setSelectedCancelReason] = useState<string>('');
  const [selectedBlockReason, setSelectedBlockReason] = useState<BlockReasons>({
    reason: '',
    altReason: '',
  });

  const { t } = useTranslation('multi-select');
  const history = useHistory();

  useEffect(() => {
    if (!numberOfSelectedSlots) history.goBack();
  }, [numberOfSelectedSlots]);

  useEffect(() => {
    setTimeout(() => {
      // FIXME: research ionic page transitions
      setLoading(false);
    }, 1000);
  }, []);

  const actionButtonDisabled = useMemo(() => {
    switch (selectedAction) {
      case SlotAction.ASSIGN:
        return !selectedStudent;
      case SlotAction.BLOCK:
        if (!selectedBlockReason) return;
        const { reason, altReason } = selectedBlockReason;
        // FIXME: hardcoded "Otros", check if the value can be turned in a constant
        return !reason || (reason === 'Otros' && !altReason);
      case SlotAction.CANCEL:
        return !selectedCancelReason;
      default:
        return false;
    }
  }, [selectedAction, selectedStudent, selectedBlockReason, selectedCancelReason]);

  const display = useMemo(() => {
    return {
      studentPicker: selectedAction === SlotAction.ASSIGN,
      blockReasons: selectedAction === SlotAction.BLOCK,
      cancelReasons: selectedAction === SlotAction.CANCEL,
      separator: selectedAction === SlotAction.CANCEL || selectedAction === SlotAction.BLOCK,
      infoBox:
        selectedAction !== SlotAction.CANCEL_ASSIGNMENT && selectedAction !== SlotAction.UNLOCK,
      actionButtonPractices: selectedAction !== SlotAction.CANCEL_ASSIGNMENT,
    };
  }, [selectedAction]);

  const infoBoxImage = useMemo(() => {
    switch (selectedAction) {
      case SlotAction.ASSIGN:
        return assignImg;
      case SlotAction.BLOCK:
        return blockImg;
      case SlotAction.CANCEL:
        return cancelImg;
      default:
        return '';
    }
  }, [selectedAction]);

  const handleDisplayPickPage = () => {
    history.push('/review/pick-student');
  };

  const handleExecuteAction = () => {
    if (!selectedAction) throw new Error('review action not set');
    handleMultiAction(
      selectedAction,
      selectedSlots,
      loggedUser,
      selectedCancelReason,
      selectedBlockReason,
      t,
      selectedStudent,
      selectedCar,
    );
    multiselectDiscardSelection();
  };

  const handleToolbarHeaderDisplay = (event: CustomEvent<IonicScrollDetail>) => {
    if (!displayTopBarHeader && event.detail.scrollTop > TOPBAR_HEADER_DISPLAY_THRESHOLD) {
      setDisplayTopBarHeader(true);
    }

    if (displayTopBarHeader && event.detail.scrollTop < TOPBAR_HEADER_DISPLAY_THRESHOLD) {
      setDisplayTopBarHeader(false);
    }
  };

  return (
    <IonPage id="review">
      <IonHeader>
        <IonToolbar
          style={{
            ...(displayTopBarHeader && {
              boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.04)',
            }),
          }}
        >
          <IonButtons slot="start">
            <IonBackButton defaultHref="/calendar" mode="md" />
          </IonButtons>
          <TopBarHeader
            className={clsx(!loading && 'not-loading', displayTopBarHeader && 'display')}
          >
            {t(`actions.${selectedAction}`)}
          </TopBarHeader>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollEvents={true} onIonScroll={handleToolbarHeaderDisplay}>
        <ReviewPageContainer>
          <header>
            <h1>{t(`actions.${selectedAction}`)}</h1>
            {display.studentPicker && (
              <StudentPicker selectedStudent={selectedStudent} onTrigger={handleDisplayPickPage} />
            )}
            <p>
              {numberOfSelectedSlots} {t('practiceSelected', { count: numberOfSelectedSlots })}
            </p>
          </header>

          <CollapsibleList show={3} itemHeightRem={6.8}>
            {selectedSlots.map((slot) => (
              <SummaryItem
                key={slot.__front_uid__}
                slot={slot}
                onDelete={() => {
                  removeSlot(slot);
                }}
              />
            ))}
          </CollapsibleList>

          {display.separator && <div className="separator" />}

          {display.cancelReasons && <CancelReasons onReasonChosen={setSelectedCancelReason} />}

          {display.blockReasons && <BlockReasons onReasonChange={setSelectedBlockReason} />}

          <section className="bottom-box">
            {display.infoBox && (
              <InfoBox
                title={t(`info-box.${selectedAction}.title`)}
                text={t(`info-box.${selectedAction}.text`)}
                image={infoBoxImage}
              />
            )}
            <SafeInsetBottom />
          </section>

          <footer>
            <IonButton disabled={actionButtonDisabled} onClick={handleExecuteAction}>
              {t(`actions.${selectedAction}`)}{' '}
              {display.actionButtonPractices && t('practice', { count: numberOfSelectedSlots })}
            </IonButton>
            <SafeInsetBottom />
          </footer>
        </ReviewPageContainer>
      </IonContent>
    </IonPage>
  );
};

export default ReviewPage;
