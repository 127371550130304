import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from './icon';
import clsx from 'clsx';

const InfoBoxContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  padding: 1.6rem 2rem;
  border-radius: 1.2rem;
  background: var(--grey-grey-3);
  align-items: center;
  gap: 0.4rem;
  opacity: 1;
  transition: opacity 0.15s linear;

  &.hidden {
    opacity: 0;
  }

  .text-content {
    flex-grow: 1;
    header {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
    }

    p {
      margin: 0.4rem 0 0 0;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  img {
    flex-shrink: 0;
    height: 8rem;
    width: auto;
  }

  .close-box {
    align-self: start;
  }
`;

type InfoBoxProps = {
  title: string;
  text: string;
  image?: string;
};

export const InfoBox: React.FC<InfoBoxProps> = ({ title, text, image }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [closed, setClosed] = useState(false);
  const [attached, setAttached] = useState(true);

  useEffect(() => {
    const __onTransitionEnd = () => {
      setAttached(false);
    };
    containerRef.current?.addEventListener('transitionend', __onTransitionEnd);

    return () => {
      containerRef.current?.removeEventListener('transitionend', __onTransitionEnd);
    };
  });

  return attached ? (
    <InfoBoxContainer ref={containerRef} className={clsx(closed && 'hidden')}>
      <div className="text-content">
        <header>{title}</header>
        <p>{text}</p>
      </div>
      {image && <img src={image} alt={title} />}
      <div className="close-box">
        <Icon name="close" clickable onClick={() => setClosed(true)} />
      </div>
    </InfoBoxContainer>
  ) : null;
};
