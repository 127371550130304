import React from 'react';
import { Icon } from './icon';

type RadioboxProps = {
  checked?: boolean;
};

export const Radiobox: React.FC<RadioboxProps> = ({ checked }) =>
  checked ? (
    <Icon name="check_circle" size="large" fill color="var(--primary-primary-500)" />
  ) : (
    <Icon name="radio_button_unchecked" size="large" />
  );
