import { DateTime, Interval } from 'luxon';

export class DateUtils {
  static getMonthName(ISODate: string) {
    const date = DateTime.fromISO(ISODate);
    return date.toLocaleString({ month: 'long' });
  }

  static getYear(ISODate: string) {
    const date = DateTime.fromISO(ISODate);
    return date.toLocaleString({ year: 'numeric' });
  }

  static getWeekDayName(ISODate: string) {
    const date = DateTime.fromISO(ISODate);
    return date.toLocaleString({ weekday: 'short' });
  }

  static getWeekInfo(ISODate: string) {
    const date = DateTime.fromISO(ISODate);
    const start = date.startOf('week');
    const end = date.endOf('week');
    const days = Interval.fromDateTimes(start, end)
      .splitBy({ days: 1 })
      .map((d) => d.start);

    return days.map((day) => ({
      number: day.toLocaleString({ day: '2-digit' }),
      name: day.toLocaleString({ weekday: 'short' }),
      iso: day.toISO(),
    }));
  }

  static isSameDay(iso1: string, iso2: string) {
    return DateTime.fromISO(iso1).hasSame(DateTime.fromISO(iso2), 'day');
  }

  static getPrevWeek(date: string) {
    return DateTime.fromISO(date).minus({ week: 1 }).toISO();
  }

  static getNextWeek(date: string) {
    return DateTime.fromISO(date).plus({ week: 1 }).toISO();
  }

  // Better use Date objects
  static isPast(date: string) {
    return DateTime.local() > DateTime.fromISO(date);
  }

  static isPastDay(date: string) {
    return DateTime.local().endOf('day') > DateTime.fromISO(date).endOf('day');
  }

  static isToday(date: string) {
    return DateTime.fromISO(date).hasSame(DateTime.local(), 'day');
  }

  static isFuture(currentDate: string, hourToCheck: string) {
    const currentDay = currentDate.split('T').shift();
    const currentHour = currentDate.split('T').pop();
    if (DateUtils.isToday(currentDate)) return !DateUtils.isPast(hourToCheck);
    return !DateUtils.isPast(currentDate);
  }

  static getDuration(start: string, end: string) {
    const startHour = parseInt(start.split(':')[0]);
    const startMinute = parseInt(start.split(':')[1]);
    const endHour = parseInt(end.split(':')[0]);
    const endMinute = parseInt(end.split(':')[1]);
    const startTime = DateTime.fromObject({
      hour: startHour,
      minute: startMinute,
    });
    const endTime = DateTime.fromObject({ hour: endHour, minute: endMinute });
    return endTime.diff(startTime, 'minutes').toObject().minutes || 0;
  }

  static getHour(hourString: string) {
    return parseInt(hourString.split(':').shift() || '', 10);
  }

  static getMinutes(hourString: string) {
    return parseInt(hourString.split(':').pop() || '', 10);
  }

  static fromISOToLocal(date: string) {
    return date.split('-').reverse().join('/');
  }

  static toLocale(isoDate: string) {
    return DateTime.fromISO(isoDate).toLocaleString({
      day: '2-digit',
      month: 'long',
    });
  }

  static fromTsToLocal(ts: number) {
    if (!ts) return null;
    return DateTime.fromMillis(ts).toLocaleString({
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  static getCurrentTimeISO() {
    return DateTime.local().toFormat('HH:mm');
  }

  /**
   * Creates a DateTime object from a date and a time
   * strings
   *
   * @param date Date separated by -
   * @param time Time separated by : format HH:mm
   * @returns {DateTime} a DateTime object
   */
  static createDate = (date: string, time: string) => {
    const [year, month, day] = date.split('-');
    const [hour, minutes] = time.split(':');

    const opts: Record<any, any> = {};
    opts.year = Number(year);
    opts.month = Number(month);
    opts.day = Number(day);
    opts.hour = Number(hour);
    opts.minutes = Number(minutes);
    opts.zone = 'Europe/Madrid';

    return DateTime.fromObject(opts);
  };

  static getTimestamp = (date: string, time: string) => DateUtils.createDate(date, time).toMillis();

  static createDateTime = DateTime;
}
