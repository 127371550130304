import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDribo } from '../contexts/driboContext';
import { PrivateRouteNavigationProps } from '../components/private-route';

import practiceService from '../services/practices';
import userService from '../services/user';
import { DateUtils } from '../services/date';
import notificationsService from '../services/notifications';
import { DriboPracticeDetail } from '../types/practice';
import { DriboStudent } from '../types/user';

import { ActionLayout } from '../components/layouts/action-layout';
import PracticeSummaryCard from '../components/practice-summary-card';
import CustomAlert from '../components/custom-alert';
import { IonSpinner } from '@ionic/react';
import { CancelReasons } from './review/cancel-reasons';

type CancelPracticePageProps = PrivateRouteNavigationProps;
const CancelPracticePage: React.FC<CancelPracticePageProps> = (props) => {
  const { loggedUser, history } = props;
  const { selectedCar } = useDribo();
  const { t } = useTranslation('cancel-practice');
  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const [practice, setPractice] = useState<DriboPracticeDetail>();
  const [student, setStudent] = useState<DriboStudent>();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    getPractice();
  }, []);

  const getPractice = () => {
    practiceService
      .fetchDetail(loggedUser.schoolUid, (props.match.params as any).practiceId.split(',')[0])
      .then((practice) => {
        setPractice(practice);
        getUser(practice.user);
      });
  };

  const getUser = (userId: string) => {
    return userService.fetchStudentProfile(userId).then((student) => setStudent(student));
  };

  const onOptionSelected = (cancelReason: string) => {
    setCancelReason(cancelReason);
  };

  const cancelPractice = (practice: DriboPracticeDetail) => {
    if (!cancelReason || !selectedCar) return;
    setShowAlert(false);
    history.goBack();
    const toastId = notificationsService.showLoadingToast(t('notifications.start'));
    const practices = props.match.params.practiceId.split(',');
    const schoolId = props.loggedUser.schoolUid;
    return Promise.all(
      practices.map((practiceId) => {
        return practiceService.cancelUserPractice(
          schoolId,
          selectedCar,
          practice.user,
          practiceId,
          cancelReason,
        );
      }),
    )
      .then((res) => {
        notificationsService.updateLoadingToast(toastId, 'success', t('notifications.success'));
      })
      .catch((e) => {
        notificationsService.updateLoadingToast(toastId, 'error', t('notifications.error'));
      });
  };

  return (
    <CancelPracticeContainer>
      <ActionLayout
        title={t('title')}
        renderSubtitle={() => {
          if (!student || !practice) return <></>;
          return (
            <div className="summary-card">
              <PracticeSummaryCard
                user={student}
                date={DateUtils.fromISOToLocal(practice.practice.date)}
                start={practice.practice.start}
                end={practice.practice.end}
                mode="row"
              />
            </div>
          );
        }}
        actions={[
          {
            label: t('buttonText'),
            onClick: () => {
              setShowAlert(true);
              return Promise.resolve();
            },
            type: 'primary',
            disabled: !cancelReason,
          },
        ]}
        separator
      >
        <div className="width-100">
          {!student || !practice || typeof loggedUser !== 'object' ? (
            <IonSpinner name="dots" />
          ) : (
            <>
              <CancelReasons onReasonChosen={(reason) => onOptionSelected(reason)} />

              <CustomAlert
                show={showAlert}
                user={student}
                practice={practice}
                onConfirm={() => cancelPractice(practice)}
                onCancel={() => setShowAlert(false)}
                title={t('alertTitle')}
                text={t('alertText')}
                confirmButtonText={t('confirmAlert')}
                cancelButtonText={t('cancelAlert')}
              />
            </>
          )}
        </div>
      </ActionLayout>
    </CancelPracticeContainer>
  );
};

export default CancelPracticePage;

const CancelPracticeContainer = styled.div`
  .summary-card {
    margin: 2.5rem 0;
  }

  p.reason {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
