import React, { useState } from 'react';
import { ActionLayout } from '../components/layouts/action-layout';
import styled from 'styled-components';
import { PrivateRouteNavigationProps } from '../components/private-route';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { DriboStudent } from '../types/user';
import Avatar from '../components/avatar';
import { getStudentName } from '../services/school';
import CustomAlert from '../components/custom-alert';
import { assignPracticeToStudent } from '../services/practices';
import { useHistory, useRouteMatch } from 'react-router-dom';
import notificationsService from '../services/notifications';
import { DateUtils } from '../services/date';
import { phone, identity_card, calendar, clock } from '../assets/icons/index';
import { SearchStudent } from '../components/search-student';
const AssignPracticeContainer = styled.div`
  .header-wrapper {
    padding: 0 1.6rem;
  }

  .header-wrapper > h1 {
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 15px;
  }

  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 2.6rem;
  }

  .search-form {
    z-index: 99;
    background: white;
    position: sticky;
    top: 0;
    padding-bottom: 2rem;
  }
  .subtitle-info {
    font-size: 14px;
    color: #707070;
    margin-right: 1rem;
    display: inline-flex;
    align-items: center;
  }

  .subtitle-info > ion-icon {
    margin-right: 0.5rem;
  }

  .subtitle {
    margin-top: 0.75rem;
  }

  .searchbar-search-icon {
    width: 1.8rem;
    left: 15px;
  }

  .searchbar-input {
    padding-inline-start: 4.2rem !important;
    font-size: 1.6rem;
  }

  .student-card {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 0.2px solid #70707030;
  }

  .student-card:last-child {
    border: none;
  }

  .student-card-content {
    padding-left: 2.4rem;
    padding-top: 0.5rem;
  }

  .student-name {
    font-size: 1.6rem;
    font-weight: 600;
  }

  .avatar {
    width: 4.8rem;
    height: 4.8rem;
  }

  .student-list {
    width: 100%;
  }

  .search-content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .checkbox {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  ion-radio {
    width: 24px;
  }

  ion-radio::part(container) {
    width: 2.3rem;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 1);
    height: 2.3rem;
  }

  ion-radio::part(mark) {
    margin-bottom: 3px;
    margin-left: 1px;
  }

  ion-radio.radio-checked {
    --color-checked: #fff;
  }

  ion-radio.radio-checked::part(container) {
    background: var(--primary-primary-500);
    border: none;
    color: #fff;
  }

  .avatar-content {
    padding: 0;
  }

  .list {
    padding-bottom: 6.4rem;
  }

  .student-details {
    margin-top: 0.5rem;
  }

  .student-details > span {
    font-size: 1.4rem;
    font-weight: 500;
    color: #707070;
    display: inline-flex;
    align-items: center;
  }

  .student-info-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .with-margin {
    margin-right: 1.5rem;
  }
`;
export const AssignPracticePage: React.FC<
  PrivateRouteNavigationProps & { students: DriboStudent[] }
> = ({ loggedUser }) => {
  const { t } = useTranslation('assign-practice');
  const match = useRouteMatch();
  const history = useHistory();
  const [studentSelected, setStudentSelected] = useState<DriboStudent | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleStudentClick = (student: DriboStudent) => {
    setStudentSelected(student);
    displayModal();
  };

  const assignPractice = async () => {
    setModalVisible(false);
    const { practiceDate } = match.params as Record<string, string>;

    if (studentSelected && !studentSelected.car) {
      return console.error('Student does not has a car assigned'); // eslint-disable-line
    }
    history.goBack();
    const toastId = notificationsService.showLoadingToast(t('notifications.start'));
    try {
      await assignPracticeToStudent(
        studentSelected!.uid,
        loggedUser.schoolUid,
        studentSelected!.car!,
        practiceDate,
        getPracticeStart(),
      );
      notificationsService.updateLoadingToast(toastId, 'success', t('notifications.success'));
    } catch (e) {
      console.error('Cannot assign practice to student:', e); // eslint-disable-line
      notificationsService.updateLoadingToast(toastId, 'error', t('notifications.error'));
    }
    return;
  };

  const displayModal = () => {
    setModalVisible(true);
  };

  const getPracticeDate = () => {
    return DateUtils.fromISOToLocal((match.params as any).practiceDate);
  };

  const getPracticeStart = () => {
    const practiceTime = (match.params as Record<string, string>).practiceTime.split('-');
    return practiceTime[0];
  };

  const getPracticeEnd = () => {
    const practiceTime = (match.params as Record<string, string>).practiceTime.split('-');
    return practiceTime[1];
  };
  return (
    <AssignPracticeContainer>
      <IonPage id="actionLayout">
        <IonHeader>
          <IonToolbar className="toolbar-wrapper">
            <IonButtons slot="start">
              <IonBackButton defaultHref="/calendar" mode="md" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent scrollY={false}>
          <div className="header-wrapper">
            <h1 className="dark">{t('title')}</h1>
            <div className="subtitle-wrapper">
              <div className="subtitle">
                <span className="subtitle-info">
                  <IonIcon icon={calendar} />
                  {getPracticeDate()}
                </span>
                <span className="subtitle-info">
                  <IonIcon icon={clock} />
                  {getPracticeStart()} a {getPracticeEnd()}
                </span>
              </div>
            </div>
          </div>

          <SearchStudent onStudentSelected={handleStudentClick} />
        </IonContent>
      </IonPage>

      {studentSelected ? (
        <CustomAlert
          show={modalVisible}
          onConfirm={assignPractice}
          onCancel={() => {
            setModalVisible(false);
          }}
          title={t('confirmModalTitle')}
          text={t('confirmModalText')}
          user={studentSelected!}
          confirmButtonText={t('confirmModalCTA')}
          cancelButtonText={t('confirmModalCancelCTA')}
          practice={
            {
              practice: {
                date: (match.params as any).practiceDate,
                start: getPracticeStart(),
                end: getPracticeEnd(),
              },
            } as any
          }
        />
      ) : null}
    </AssignPracticeContainer>
  );
};
