import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { IonRadioGroup, IonItem, IonLabel, IonRadio } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const BlockReasonsContainer = styled.section`
  width: 100%;
  margin-bottom: 4rem;

  header {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
  }

  ion-label {
    margin: 0 !important;
  }

  ion-radio-group ion-item::part(native) {
    padding-inline-start: 0;
  }

  ion-radio {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
  }

  ion-radio::part(container) {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 0.16rem solid var(--grey-grey-11);
  }

  ion-radio::part(mark) {
    margin-bottom: 2px;
    border-width: 2px;
    border-top-width: 0;
    border-left-width: 0;
    width: 33%;
    height: 63%;
  }

  ion-radio.radio-checked {
    --color-checked: #fff;
  }

  ion-radio.radio-checked::part(container) {
    background: var(--primary-primary-500);
    border: none;
    color: #fff;
  }

  .item {
    color: var(--grey-grey-11);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    height: 3.5rem;
  }

  input {
    width: 72%;
    margin: auto;
    border: none;
    border-bottom: 1px solid var(--grey-grey-11);
    height: 2.4rem;
    color: var(--grey-grey-8);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;

    &[disabled] {
      background: none;
      border-bottom: 1px solid var(--grey-grey-5);
      color: var(--grey-grey-5);
      outline: none;
    }

    &:focus {
      border-bottom: 1px solid var(--primary-primary-500);
      outline: none;
    }
  }
`;

export type BlockReasons = {
  reason: string;
  altReason: string;
};

type BlockReasonsProps = {
  onReasonChange: ({ reason, altReason }: { reason: string; altReason: string }) => void;
};

export const BlockReasons: React.FC<BlockReasonsProps> = ({ onReasonChange }) => {
  const [value, setValue] = useState<string>('');
  const [altReason, setAltReason] = useState('');
  const otherReasonFocus = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation('lock-practice');

  const handleReasonChange = (value: string) => {
    setValue(value);

    if (value === t('other')) {
      otherReasonFocus.current?.focus();
    }

    onReasonChange({ reason: value, altReason });
  };

  const handleAltReasonChange = (altReason: string) => {
    setAltReason(altReason);
    onReasonChange({ reason: value, altReason });
  };

  return (
    <BlockReasonsContainer>
      <header>{t('radioGroupTitle')}</header>
      <IonRadioGroup value={value} onIonChange={(e) => handleReasonChange(e.detail.value)}>
        <IonItem lines="none" className="item">
          <IonLabel>{t('exam')}</IonLabel>
          <IonRadio slot="start" value={t('exam')} mode="ios" />
        </IonItem>

        <IonItem lines="none" className="item">
          <IonLabel>{t('holidays')}</IonLabel>
          <IonRadio slot="start" value={t('holidays')} mode="ios" />
        </IonItem>

        <IonItem lines="none" className="item">
          <IonLabel>{t('illness')}</IonLabel>
          <IonRadio slot="start" value={t('illness')} mode="ios" />
        </IonItem>

        <IonItem lines="none" className="item">
          <IonLabel>{t('carRepair')}</IonLabel>
          <IonRadio slot="start" value={t('carRepair')} mode="ios" />
        </IonItem>

        <div className="flex">
          <IonItem lines="none" className="item">
            <IonLabel>{t('other')}:</IonLabel>
            <IonRadio slot="start" value={t('other')} mode="ios" />
          </IonItem>
          <input
            ref={otherReasonFocus}
            disabled={value !== t('other')}
            onChange={(e) => handleAltReasonChange(e.target.value)}
          />
        </div>
      </IonRadioGroup>
    </BlockReasonsContainer>
  );
};
