import { getDefaultStore } from 'jotai';
import { assignedPracticeToCancelAtom, selectedSlotAtom } from './atoms';
import notificationsService from '@/services/notifications';
import practiceService from '@/services/practices';
import { DateUtils } from '@/services/date';
import { SlotAction } from '../slot';
import { userAtom } from '../user';
import type { History } from 'history';
import type { TFunction } from 'i18next';

const store = getDefaultStore();

export const handleSingleAction = (
  action: SlotAction,
  history: History,
  t: TFunction,
  car: string | null,
) => {
  switch (action) {
    case SlotAction.CANCEL:
      cancelPractice(history);
      break;
    case SlotAction.BLOCK:
      blockPractice(history, car!);
      break;
    case SlotAction.UNLOCK:
      unlockPractice(t, car!);
      break;
    case SlotAction.ASSIGN:
      assignPractice(history);
      break;
    case SlotAction.CANCEL_ASSIGNMENT:
      cancelAssignedPractice();
      break;
  }
  store.set(selectedSlotAtom, null);
};

const cancelPractice = (history: History) => {
  const practice = store.get(selectedSlotAtom);
  if (!practice) return;
  if (practice.includedPractices) {
    const includedIds = Object.values(practice.includedPractices).join(',');
    return history.push(`/cancel-practice/${includedIds}`);
  }
  history.push(`/cancel-practice/${practice.practiceId}`);
};

const blockPractice = (history: History, car: string) => {
  const practice = store.get(selectedSlotAtom);
  if (!practice) return;
  const { date, start, end } = practice;
  const localDay = DateUtils.fromISOToLocal(date);
  const localDayReverse = localDay.split('/').reverse().join('/');
  history.push(`/lock-practice/${car}/${localDayReverse}/${start}-${end}`);
};

const unlockPractice = (t: TFunction, car: string) => {
  const practice = store.get(selectedSlotAtom);
  const loggedUser = store.get(userAtom);
  if (!practice) return;
  const { date, start } = practice;
  store.set(selectedSlotAtom, null);

  const toastId = notificationsService.showLoadingToast(t('notifications.unlock.start'));
  return practiceService
    .unlockTeacherSlot(date, start, loggedUser!.schoolUid, car)
    .then(() => {
      notificationsService.updateLoadingToast(
        toastId,
        'success',
        t('notifications.unlock.success'),
      );
    })
    .catch(() => {
      notificationsService.updateLoadingToast(toastId, 'error', t('notifications.unlock.error'));
    });
};

const assignPractice = (history: History) => {
  const practice = store.get(selectedSlotAtom);
  if (!practice) return;
  const { date, start, end } = practice;
  history.push(`/assign-practice/${date}/${start}-${end}`);
};

const cancelAssignedPractice = () => {
  const practice = store.get(selectedSlotAtom);
  if (!practice || !practice.user || !practice.practiceId) return;
  store.set(assignedPracticeToCancelAtom, practice);
};
