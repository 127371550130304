import practiceService from '@/services/practices';
import type { DriboTeacher } from '@/types/user';
import type { BookedSlotWithRange } from '@/types/practice';
import { getDefaultStore } from 'jotai';
import { dayAtom, loadingAtom, practicesMapAtom } from './atoms';
import { userAtom } from '../user';
import { carAtom } from '../car';
import { multiSelectedDaysAtom } from '../multi-select/atoms';
import {
  multiselectBackendUpdateConsistencyCheck,
  multiselectDiscardSelection,
} from '../multi-select';

const store = getDefaultStore();

const dayAvailabilityObservers: Record<string, () => void> = {};
let storeSubscriptions: (() => void)[] = [];

const __filterMultiplePractices = (slots: BookedSlotWithRange[]) => {
  const includedIds = slots
    .filter((practice) => practice.multiple)
    .reduce((acc: string[], val) => {
      if (val.includedPractices) {
        Object.values(val.includedPractices).forEach((id) => acc.push(id));
      }
      return acc;
    }, []);

  return slots.reduce((filteredPractices: BookedSlotWithRange[], practice) => {
    if (practice.user) {
      if (practice.user.role === 'school') filteredPractices.push(practice);
      if (practice.multiple) {
        practice.end = practice.lastPracticeEnd ? practice.lastPracticeEnd : practice.end;
        filteredPractices.push(practice);
      } else if (practice.practiceId && !includedIds.includes(practice.practiceId))
        filteredPractices.push(practice);
    } else filteredPractices.push(practice);
    return filteredPractices;
  }, []);
};

const _fetchSlots = (
  loggedUser: DriboTeacher,
  car: string,
  day: string,
): Promise<BookedSlotWithRange[]> => {
  return practiceService.fetchTeacherBookedPractices(loggedUser, car, day);
};

const __dettachAndDeleteObserverRecord = (key: string) => {
  const dettach = dayAvailabilityObservers[key];
  dettach();
  delete dayAvailabilityObservers[key];
};

const __clearUnnecessaryObservers = () => {
  const selectedDayInView = store.get(dayAtom);
  const daysToWatchMultiselect = store.get(multiSelectedDaysAtom);

  const unnecesaryObserversKeys = Object.keys(dayAvailabilityObservers).filter(
    (day) => ![...daysToWatchMultiselect, selectedDayInView].includes(day),
  );

  unnecesaryObserversKeys.map(__dettachAndDeleteObserverRecord);
};

const __observeDayAvailability = (day: string, isDayChange = false) => {
  const teacher = store.get(userAtom) as DriboTeacher;
  const car = store.get(carAtom) as string;

  dayAvailabilityObservers[day] = practiceService.listenTeacherPractices(
    teacher,
    car,
    day,
    async () => {
      const slots = await _fetchSlots(teacher, car, day);
      const practicesMap = store.get(practicesMapAtom);

      store.set(practicesMapAtom, { ...practicesMap, [day]: __filterMultiplePractices(slots) });
      if (isDayChange) {
        store.set(loadingAtom, false);
      }

      multiselectBackendUpdateConsistencyCheck(slots);
    },
  );
};

const __handleDayChanged = () => {
  store.set(loadingAtom, true);
  const day = store.get(dayAtom);
  if (!dayAvailabilityObservers[day]) {
    __observeDayAvailability(day, true);
  } else {
    setTimeout(() => {
      store.set(loadingAtom, false);
    }, 200);
  }
  __clearUnnecessaryObservers();
};

const __handleCarChanged = () => {
  multiselectDiscardSelection();
  Object.keys(dayAvailabilityObservers).map(__dettachAndDeleteObserverRecord);
  __handleDayChanged();
};

const __handleMultiSelectSelectionChanged = () => {
  const multiSelectedDays = store.get(multiSelectedDaysAtom);

  for (const day of multiSelectedDays) {
    if (!dayAvailabilityObservers[day]) {
      __observeDayAvailability(day);
    }
  }
  __clearUnnecessaryObservers();
};

export const destroy = () => {
  storeSubscriptions.map((unsubscribe) => unsubscribe());
  storeSubscriptions = [];
};

export const initialise = () => {
  const dayChangeSub = store.sub(dayAtom, __handleDayChanged);
  const carChangeSub = store.sub(carAtom, __handleCarChanged);
  const multiSelectChangeSub = store.sub(
    multiSelectedDaysAtom,
    __handleMultiSelectSelectionChanged,
  );

  storeSubscriptions = [dayChangeSub, carChangeSub, multiSelectChangeSub];

  store.set(dayAtom, new Date().toISOString());
};
