import React from 'react';
import type { BadgeProps } from './badge';
import type { IconName, IconProps, IconSize } from '../icon';
import { Badge } from './badge';
import { Icon } from '../icon';

type IconBadgeProps = {
  icon: IconName;
  iconSize?: IconSize;
  backgroundColor?: string;
  onClick?: () => void;
} & BadgeProps &
  Omit<IconProps, 'name' | 'size'>;

export const IconBadge: React.FC<IconBadgeProps> = ({
  icon,
  size = 'small',
  iconSize = 'medium',
  clickable,
  fill,
  backgroundColor = 'var(--primary-primary-50)',
  color = 'var(--primary-primary-500)',
  onClick = () => {},
  ...rest
}) => {
  return (
    <Badge
      style={{
        ...(backgroundColor && { backgroundColor }),
        ...(clickable && { cursor: 'pointer' }),
      }}
      {...{ size, onClick, ...rest }}
    >
      <Icon name={icon} size={iconSize} {...{ clickable, color, fill, onClick }} />
    </Badge>
  );
};
