import React from 'react';
import styled from 'styled-components';
import { clsx } from 'clsx';
import { UserBadge } from '@/components/badge/user-badge';
import { IconBadge } from '@/components/badge/icon-badge';
import { getFullName } from '@/logic/user';
import type { DriboStudent } from '@/types/user';

const StudentPickerContainer = styled.div`
  padding: 0.8rem 0 1.6rem;

  .student-picker {
    padding: 0.8rem;
    display: flex;
    gap: 1.2rem;
    border-radius: 1.2rem;
    align-items: center;

    &:hover {
      background-color: var(--grey-grey-21);
    }

    &__text {
      flex-grow: 1;
      color: var(--primary-primary-500);
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2rem;
      cursor: pointer;

      &--student-picked {
        color: var(--grey-grey-11);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: auto;
      }
    }

    &__change {
      padding: 0.8rem 1.2rem;
      color: var(--primary-primary-500);

      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
      cursor: pointer;
    }
  }
`;

type StudentPickerProps = {
  selectedStudent?: DriboStudent;
  onTrigger?: () => void;
};

export const StudentPicker: React.FC<StudentPickerProps> = ({
  selectedStudent,
  onTrigger = () => {},
}) => (
  <StudentPickerContainer>
    <div className="student-picker">
      {selectedStudent ? (
        <UserBadge user={selectedStudent} />
      ) : (
        <IconBadge icon="add" clickable iconSize="large" onClick={onTrigger} />
      )}
      <div
        className={clsx(
          'student-picker__text',
          selectedStudent && 'student-picker__text--student-picked',
        )}
        onClick={() => {
          !selectedStudent && onTrigger();
        }}
      >
        {selectedStudent ? getFullName(selectedStudent) : 'Seleccionar alumno'}
      </div>
      {selectedStudent && (
        <div className="student-picker__change" onClick={onTrigger}>
          Cambiar
        </div>
      )}
    </div>
  </StudentPickerContainer>
);
