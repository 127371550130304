import { useAtomValue } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import {
  multiSelectEnabledAtom,
  selectedSlotsIdAtom,
  selectedSlotsStatusAtom,
} from '@/logic/multi-select/atoms';
import { getSlotStatus } from '@/logic/slot';
import styled from 'styled-components';
import { Radiobox } from '@/components/check-indicator';
import { useTranslation } from 'react-i18next';
import { addSlotsToSelection, removeSlotsFromSelection } from '@/logic/multi-select';
import { practicesAtom } from '@/logic/calendar/atoms';
import clsx from 'clsx';

const SelectAllContainer = styled.div`
  margin: 0;
  padding: 0;
  text-align: right;
  height: 0px;
  transition: height 0.4s cubic-bezier(0.36, 0.66, 0.04, 1);
  overflow: hidden;

  &.visible {
    padding: 0.8rem 1.6rem;

    border-top: 0.2rem solid var(--grey-grey-3);
    height: 4.2rem;
  }
`;

const SelectMultipleButton = styled.button`
  display: inline-flex;
  color: var(--grey-grey-11);
  align-items: center;
  background: none;
  gap: 0.8rem;
  font-size: 1.4rem;
  font-weight: 600
  line-height: 1.8rem;
  padding-block: 0;

  &.disabled {
    opacity: 1 !important;
    color: var(--grey-grey-6);
  }
`;

export const SelectAll: React.FC = () => {
  const currentDayPractices = useAtomValue(practicesAtom);
  const multiSelectEnabled = useAtomValue(multiSelectEnabledAtom);
  const selectedSlotsId = useAtomValue(selectedSlotsIdAtom);
  const selectedSlotsStatus = useAtomValue(selectedSlotsStatusAtom);
  const { t } = useTranslation('multi-select');

  const applicableSlots = useMemo(() => {
    return selectedSlotsStatus
      ? currentDayPractices.filter((slot) => getSlotStatus(slot) === selectedSlotsStatus)
      : [];
  }, [selectedSlotsStatus, currentDayPractices]);

  const aplicableSlotsAreSelected = useMemo(() => {
    if (!applicableSlots.length) return false;
    return applicableSlots
      .map((slot) => slot.__front_uid__)
      .every((slot) => selectedSlotsId.includes(slot));
  }, [applicableSlots, currentDayPractices, selectedSlotsId]);

  const handleSelectMultipleClick = useCallback(() => {
    if (!applicableSlots.length) return;

    if (aplicableSlotsAreSelected) {
      removeSlotsFromSelection(applicableSlots);
    } else {
      addSlotsToSelection(applicableSlots);
    }
  }, [aplicableSlotsAreSelected, applicableSlots, addSlotsToSelection]);

  if (!multiSelectEnabled)
    return <SelectAllContainer className={clsx(multiSelectEnabled && 'visible')} />;

  return (
    <SelectAllContainer className={clsx(multiSelectEnabled && 'visible')}>
      <SelectMultipleButton
        className={clsx(!applicableSlots.length && 'disabled')}
        onClick={handleSelectMultipleClick}
      >
        {aplicableSlotsAreSelected ? t('cancelSelection') : t('selectAll')}{' '}
        <Radiobox checked={aplicableSlotsAreSelected} />
      </SelectMultipleButton>
    </SelectAllContainer>
  );
};
