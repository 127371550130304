import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DriboStudent } from '../types/user';
import userService from '../services/user';

import { UserBadge } from './badge/user-badge';
import { Icon } from './icon';

const PracticeSummaryCardContainer = styled.div`
  .main-text-large {
    margin-bottom: 0.8rem;
  }

  span {
    margin-left: 0.6rem;
    padding-top: 0.05rem;
  }

  .date {
    margin-right: 0.9rem;
  }

  ion-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  .user-badge-row {
    margin-right: 1.6rem;
  }

  .user-badge-col {
    margin-bottom: 0.8rem;
  }
`;
interface PracticeSummaryCardProps {
  user: DriboStudent;
  date: string;
  start: string;
  end: string;
  mode: 'row' | 'col';
}
const PracticeSummaryCard: React.FC<PracticeSummaryCardProps> = ({
  user,
  date,
  start,
  end,
  mode,
}) => {
  const { t } = useTranslation('calendar');
  return (
    <PracticeSummaryCardContainer
      className={`${mode === 'col' ? 'flex-vertical justify-center align-items-center ' : ''} ${
        mode === 'row' ? 'flex' : ''
      }`}
    >
      <UserBadge user={user} size="x-large" className={`user-badge-${mode}`} />
      <div className="flex-vertical justify-center">
        <div className="main-text-large semibold">{userService.fullName(user)}</div>
        <div className="flex">
          <div className="flex">
            <Icon name="calendar_today" size="small" />
            <span className="main-text-small date">{date}</span>
          </div>
          <div className="flex">
            <Icon name="schedule" size="small" />
            <span className="main-text-small">
              {start} {t('to')} {end}
            </span>
          </div>
        </div>
      </div>
    </PracticeSummaryCardContainer>
  );
};

export default PracticeSummaryCard;
