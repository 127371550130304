import React from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonIcon,
  IonButton,
} from '@ionic/react';
import LoadingButton from '../components/loading-button';
import userService from '../services/user';
import notificationsService from '../services/notifications';
import { withTranslation } from 'react-i18next';
import Input from '../components/input';
import loginImg from '../assets/img/img_login.png';
import config from '../config';
import { Translated } from '../types/route';
import { RouteComponentProps } from 'react-router';
import { PasswordRecover } from '@/components/auth/password-recover';
import { eye, eyeOff, arrowBackOutline } from 'ionicons/icons';
import styled from 'styled-components';

// This will get stripped on production mode
const getDevCredentials = () => {
  if (
    process.env.NODE_ENV === 'development' &&
    config.credentials &&
    config.credentials.email &&
    config.credentials.password
  ) {
    return {
      email: config.credentials.email,
      pass: config.credentials.password,
    };
  }

  return {};
};
type LoginPageProps = Translated & RouteComponentProps;

interface LoginPageState {
  form: { email?: string; pass?: string };
  passwordVisible: boolean;
  renderStep?: 'signIn' | 'passwordRecover';
}
class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  errorToastId: React.ReactText | null = null;

  state: LoginPageState = {
    form: getDevCredentials(),
    passwordVisible: false,
  };

  componentDidMount = () => {
    const { location } = this.props;
    if (location.pathname === '/login') {
      this.setState({ renderStep: 'signIn' });
    }
    if (location.pathname === '/login/password-reset') {
      this.setState({ renderStep: 'passwordRecover' });
    }
  };

  onInputValueChange(value: string, key: string) {
    this.setState((ostate) => ({
      ...ostate,
      form: {
        ...ostate.form,
        [key]: value,
      },
    }));
  }

  submitForm(form: { email?: string; pass?: string }) {
    const { t } = this.props;
    if (!form.email && !form.pass) return;
    if (this.errorToastId) {
      notificationsService.closeToast(this.errorToastId);
      this.errorToastId = null;
    }
    return userService
      .login(form.email!, form.pass!)
      .then()
      .catch((error) => {
        if (!!error && !this.errorToastId)
          this.errorToastId = notificationsService.showErrorToast(t(error));
      });
  }

  renderAuthStep = () => {
    if (!this.state.renderStep) return <></>;
    if (this.state.renderStep === 'signIn') {
      return this.renderSignIn();
    }
    if (this.state.renderStep === 'passwordRecover') {
      return this.renderPasswordRecovery();
    }
  };

  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  redirectToPasswordRecovery = () => {
    this.setState({ renderStep: 'passwordRecover' });
  };

  redirectToSignIn = () => {
    this.setState({ renderStep: 'signIn' });
  };

  onBackToSignIn = () => {
    const { history, location } = this.props;
    if (location.pathname === '/login') {
      return this.setState({ renderStep: 'signIn' });
    }

    if (location.pathname === '/login/password-reset') {
      return history.push('/login');
    }
  };

  renderSignIn = () => {
    const { form } = this.state;
    const { t } = this.props;
    return (
      <div className="login-container round-top">
        <div className="welcome">
          <h1 className="center title-1 semibold">{t('welcome')}</h1>
          <h2 className="center main-text">{t('inputData')}</h2>
        </div>
        <div>
          <form className="form-area">
            <div className="email-field">
              <Input
                autocomplete
                name="email"
                label={t('email')}
                onChange={(value) => this.onInputValueChange(value, 'email')}
                value={this.state.form.email}
              />
            </div>
            <Input
              autocomplete
              name="password"
              label={t('password')}
              onChange={(value) => this.onInputValueChange(value, 'pass')}
              type={this.state.passwordVisible ? '' : 'password'}
              value={this.state.form.pass}
              icon={this.state.passwordVisible ? eye : eyeOff}
              onIconClick={this.togglePasswordVisibility}
            />
            <div>
              <a
                className="pointer main-text-small link-text"
                onClick={this.redirectToPasswordRecovery}
              >
                {t('forgot_password_link')}
              </a>
            </div>
          </form>
          <LoadingButton
            className="submitButton"
            onClick={async () => this.submitForm(form)}
            expand="block"
          >
            {t('startSession')}
          </LoadingButton>
        </div>
      </div>
    );
  };

  renderPasswordRecovery = () => {
    return <PasswordRecover backToSignIn={this.onBackToSignIn} />;
  };

  renderToolbar = () => {
    if (this.state.renderStep === 'passwordRecover') {
      return (
        <IonHeader className="toolbar">
          <IonToolbar className="transparent-toolbar" color="transparent">
            <IonButtons slot="start" onClick={this.onBackToSignIn}>
              <IonButton>
                <IonIcon icon={arrowBackOutline} className="back-button" />
                <a className="back-button-text">Iniciar Sesión</a>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      );
    }

    return <></>;
  };

  render() {
    return (
      <StyleContainer>
        <IonPage id="login">
          {this.renderToolbar()}
          <IonContent>
            <div className="background">{this.renderAuthStep()}</div>
          </IonContent>
        </IonPage>
      </StyleContainer>
    );
  }
}

const StyleContainer = styled.div`
  .toolbar {
    position: absolute;
    top: 0;
    right: 0;
  }

  .transparent-toolbar {
    background: transparent;
    display: flex;
  }
  #login ion-content .background {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(${loginImg}) no-repeat;
    background-size: contain;
  }

  .round-top {
    background-color: white;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .welcome {
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 1rem;
  }

  .content-area {
    width: 100%;
  }

  .login-container {
    width: 100%;
    padding: 2rem;
    padding-top: 4rem;
  }

  .form-area {
    margin-bottom: 7rem;
    margin-top: 3.3rem;
  }

  .back-button {
    font-size: 20px;
    color: #fff;
  }

  .back-button-text {
    font-size: 14px;
    color: #fff;
    margin-left: 10px;
  }

  .email-field {
    margin-bottom: 1.6rem;
  }

  ion-item {
    margin: 1rem 0;
    --color: #8d8d8d;
    --border-color: #8d8d8d;
    --border-radius: 4px;
    --border-width: 0;
    --min-height: 4.8rem !important;
    --background-focused: var(--ion-color-primary-shade);
    --background-focused-opacity: 1;
    width: 100%;
    --background: var(--ion-color-input-bg);
    --background-active: var(--ion-color-primary-darker);
  }

  ion-item::part(native) {
    padding-left: 1.5rem;
  }

  ion-item ion-input {
    --color: var(--ion-color-dark-text) !important;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    margin-bottom: 0;
    font-size: 1.6rem !important;
  }

  ion-label {
    font-size: 1.5rem !important;
    --color: var(--ion-color-light-text) !important;
    margin-top: 0.7rem;
  }

  ion-item:not(.item-has-focus):not(.item-has-value) ion-label.label-floating {
    transform: translate3d(0, 1.5rem, 0) !important;
  }

  ion-item.item-has-focus ion-label.label-floating {
    color: var(--ion-color-primary);
  }

  .input-icon {
    position: absolute;
    top: 13px;
    right: 13px;
    z-index: 99;
  }
`;

export default withTranslation('login')(LoginPage);
