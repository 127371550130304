import { DateUtils } from '@/services/date';
import type { BookedSlotWithRange } from '@/types/practice';
import { IconName } from '@/components/icon';

export enum SlotStatus {
  FREE = 'free',
  PAST_FREE = 'past-free',
  LOCKED = 'locked',
  BOOKED = 'booked',
  PAST = 'past',
  PRE_BOOKED = 'pre-booked',
  NO_SHOW = 'no-show',
}

export enum SlotAction {
  ASSIGN = 'assign',
  BLOCK = 'block',
  CANCEL_ASSIGNMENT = 'cancel-assignment',
  UNLOCK = 'unlock',
  CANCEL = 'cancel',
}

export type ActionConfig = { action: SlotAction; icon: IconName };

export const isNoShow = (slot: BookedSlotWithRange) => {
  return slot.notAppeared;
};

export const isPast = (slot: BookedSlotWithRange) => {
  return DateUtils.isPast(`${slot.date}T${slot.start}`);
};

export const isPastFree = (slot: BookedSlotWithRange) => {
  return isPast(slot) && !isBooked(slot);
};

export const isLocked = (slot: BookedSlotWithRange) => {
  return slot.user && slot.user.role === 'school' && slot.status !== 'pre-booked';
};

export const isPrebooked = (slot: BookedSlotWithRange) => {
  return slot.status && slot.status === 'pre-booked';
};

export const isBooked = (slot: BookedSlotWithRange) => {
  return slot.user && slot.user.role !== 'school' && slot.status !== 'pre-booked';
};

export const isAssigned = (slot: BookedSlotWithRange) => {
  return slot.status === 'pre-booked' && slot.expires;
};

export const getSlotStatus: (slot: BookedSlotWithRange) => SlotStatus = (slot) => {
  if (isNoShow(slot)) return SlotStatus.NO_SHOW;
  else if (isPastFree(slot)) return SlotStatus.PAST_FREE;
  else if (isPast(slot)) return SlotStatus.PAST;
  else if (isPrebooked(slot)) return SlotStatus.PRE_BOOKED;
  else if (isLocked(slot)) return SlotStatus.LOCKED;
  else if (isBooked(slot)) return SlotStatus.BOOKED;
  else return SlotStatus.FREE;
};

export const getActionsForStatus = (status: SlotStatus | undefined): ActionConfig[] => {
  switch (status) {
    case SlotStatus.FREE:
      return [
        {
          icon: 'person_add',
          action: SlotAction.ASSIGN,
        },
        {
          icon: 'lock',
          action: SlotAction.BLOCK,
        },
      ];
    case SlotStatus.PRE_BOOKED:
      return [
        {
          icon: 'person_off',
          action: SlotAction.CANCEL_ASSIGNMENT,
        },
      ];
    case SlotStatus.LOCKED:
      return [
        {
          icon: 'lock_open',
          action: SlotAction.UNLOCK,
        },
      ];
    case SlotStatus.BOOKED:
      return [{ icon: 'block', action: SlotAction.CANCEL }];
    default:
      return [];
  }
};

export const isNotMultiselectable = (status: SlotStatus) => {
  return [SlotStatus.PAST, SlotStatus.PAST_FREE, SlotStatus.NO_SHOW].includes(status);
};
