import React from 'react';
export const CalendarBackground = () => (
  <>
    <div className="calendar-background">
      {[...Array(18).keys()].map((hour, i) => (
        <div key={i} className="border-top" />
      ))}
    </div>
    <div className="calendar-separator" />
  </>
);
