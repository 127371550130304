import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';

import { PrivateRouteNavigationProps } from '@/components/private-route';
import { SearchStudent } from '@/components/search-student';
import { selectedActionAtom, selectedStudentAtom } from '@/logic/multi-select/atoms';
import { DriboStudent } from '@/types/user';
import { useHistory } from 'react-router-dom';

const TopBarHeader = styled.h1`
  margin-left: 1.2rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--base-black);
`;

const PickStudentContainer = styled.article``;

type PickStudentProps = PrivateRouteNavigationProps;
const PickStudent: React.FC<PickStudentProps> = () => {
  const { t } = useTranslation('multi-select');
  const selectedAction = useAtomValue(selectedActionAtom);
  const setSelectedStudent = useSetAtom(selectedStudentAtom);
  const history = useHistory();

  useEffect(() => {
    if (!selectedAction) history.push('/calendar');
  }, [selectedAction]);

  const handleStudentSelected = (student: DriboStudent) => {
    setSelectedStudent(student);
    history.goBack();
  };

  return (
    <IonPage id="pickStudent">
      <IonHeader>
        <IonToolbar className="toolbar-wrapper">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/review" mode="md" />
          </IonButtons>
          <TopBarHeader>{t('pickStudent')}</TopBarHeader>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} scrollY={false}>
        <PickStudentContainer>
          <SearchStudent onStudentSelected={handleStudentSelected} />
        </PickStudentContainer>
      </IonContent>
    </IonPage>
  );
};

export default PickStudent;
