import notificationsService from '@/services/notifications';
import practiceService, { assignPracticeToStudent, unassignPractice } from '@/services/practices';
import { SlotAction } from '../slot';
import type { DriboStudent, DriboTeacher } from '@/types/user';
import type { BookedSlotWithRange } from '@/types/practice';
import { BlockReasons } from '@/pages/review/block-reasons';
import { TFunction } from 'i18next';

export const handleMultiAction = async (
  action: SlotAction,
  slots: BookedSlotWithRange[],
  teacher: DriboTeacher,
  cancelReason: string,
  blockReason: BlockReasons,
  t: TFunction,
  student?: DriboStudent,
  car?: string | null,
) => {
  const toastId = notificationsService.showLoadingToast(
    t(`notifications.${action}.start`, { count: slots.length }),
  );

  try {
    const requests = (() => {
      switch (action) {
        case SlotAction.ASSIGN:
          if (!student) throw new Error('student not set');
          return slots.map((slot) =>
            assignPracticeToStudent(
              student.uid,
              teacher.schoolUid,
              student.car!,
              slot.date,
              slot.start,
            ),
          );

        case SlotAction.CANCEL_ASSIGNMENT:
          if (!car) throw new Error('car not set');
          return slots.map((slot) =>
            unassignPractice(teacher.schoolUid, car, slot.user!.uid, slot.practiceId!),
          );

        case SlotAction.CANCEL:
          if (!car) throw new Error('car not set');
          return slots.flatMap((slot) => {
            const practiceIds: string[] = slot.includedPractices
              ? Object.values(slot.includedPractices)
              : [slot.practiceId!];

            return practiceIds.map((practiceId) =>
              practiceService.cancelUserPractice(
                teacher.schoolUid,
                car,
                slot!.user!.uid,
                practiceId,
                cancelReason,
              ),
            );
          });

        case SlotAction.BLOCK:
          if (!car) throw new Error('car not set');
          return slots.map((slot) => {
            const { reason, altReason } = blockReason;
            return practiceService.lockTeacherSlot(
              slot.date,
              slot.start,
              teacher.schoolUid,
              car,
              reason !== 'Otros' ? reason : altReason || 'Otros',
            );
          });

        case SlotAction.UNLOCK:
          return slots.map((slot) =>
            practiceService.unlockTeacherSlot(slot.date, slot.start, teacher.schoolUid, car!),
          );

        default:
          throw new Error('unsupported action');
      }
    })();

    await Promise.all(requests).catch((e) => {
      throw e;
    });

    notificationsService.updateLoadingToast(
      toastId,
      'success',
      t(`notifications.${action}.success`, { count: slots.length }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`[multi-select]:performSlotsAction - error executing action (${action})`, e);
    notificationsService.updateLoadingToast(toastId, 'error', t(`notifications.${action}.error`));
  }
};
