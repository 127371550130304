import React from 'react';
import styled from 'styled-components';

import { DriboStudent } from '../types/user';
import { DriboPracticeDetail } from '../types/practice';

import { DateUtils } from '../services/date';

import { IonModal, IonButton } from '@ionic/react';
import PracticeSummaryCard from './practice-summary-card';

const CustomAlertContainer = styled.div`
  h2.alert-title {
    padding: 0 1.2rem;
    color: var(--base-black);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.4rem;
  }

  p.alert-description {
    padding: 0 1.2rem;
    margin: 0.8rem 0 1.2rem;
    color: var(--grey-grey-11);
    font-size: 16px;
    font-weight: 400;
    line-height: var(--Spacing-size-size-5, 20px);
  }

  .button-section {
    margin-top: 1.2rem;
  }

  ion-button {
    margin: 0;
  }

  ion-button.button-confirm {
    height: 4.8rem;
    font-size: 1.8rem;
    line-height: 2.4px;
    text-transform: none;
    --border-radius: 2.4rem;
  }

  ion-button.button-discard {
    margin-top: 0.4rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    --background-hover: none;
  }
`;

interface CustomAlertProps {
  show: boolean;
  user?: DriboStudent;
  practice?: DriboPracticeDetail;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  text: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  show,
  user,
  practice,
  onConfirm,
  onCancel,
  title,
  text,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <IonModal isOpen={show} cssClass="custom-alert-modal center" onWillDismiss={onCancel}>
      <CustomAlertContainer className="flex-vertical between height-100">
        <div className="flex-vertical">
          <h2 className="alert-title">{title}</h2>
          <p className="alert-description">{text}</p>
        </div>
        {practice ? (
          <PracticeSummaryCard
            user={user!}
            date={DateUtils.fromISOToLocal(practice.practice.date)}
            start={practice.practice.start}
            end={practice.practice.end}
            mode="col"
          />
        ) : null}
        <div className="flex-vertical button-section">
          <IonButton onClick={onConfirm} className="button-confirm">
            {confirmButtonText}
          </IonButton>
          <IonButton onClick={onCancel} fill="clear" className="button-discard">
            {cancelButtonText}
          </IonButton>
        </div>
      </CustomAlertContainer>
    </IonModal>
  );
};

export default CustomAlert;
