import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PrivateRouteNavigationProps } from '../components/private-route';
import { IonPage, IonContent, IonIcon, IonButton } from '@ionic/react';

import carImg from '@/assets/img/car.png';

import { look, wheel, fuel, clean, docs } from '../assets/icons/index';

const ActionsAfterNotAppearedContainer = styled.div`
  height: 100%;

  .header {
    background: url(${carImg}) no-repeat;
    background-size: cover;
    background-position: right;
    height: 28.3rem;
    position: relative;
  }
  .header::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    content: ' ';
  }

  .actions {
    padding: 0 1.6rem;
  }

  .action-icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  .action-icon-container {
    width: 4rem;
    height: 4rem;
    background-color: var(--ion-color-light-gray);
    margin-right: 1.9rem;
  }

  .main-button {
    margin: 0 1.6rem 1.8rem 1.6rem;
  }
`;

type ActionsAfterNotAppearedPageProps = PrivateRouteNavigationProps;
const ActionsAfterNotAppeared: React.FC<ActionsAfterNotAppearedPageProps> = (props) => {
  const { t } = useTranslation('actions-after-not-appeared');

  const goToCalendar = () => {
    return props.history.replace('/calendar');
  };

  const icons = [look, wheel, fuel, clean, docs];
  const iconNames = ['look', 'wheel', 'fuel', 'clean', 'docs'];

  return (
    <IonPage>
      <IonContent>
        <ActionsAfterNotAppearedContainer>
          <div className="flex-vertical height-100">
            <div className="header flex align-items-center justify-center relative">
              <div className="supertitle white center absolute">
                <span className="black">{t('answeredBoldTitle')}</span>
                <br /> {t('answeredRegularTitle')}
              </div>
            </div>
            <div className="actions flex-vertical even flex-1">
              {icons.map((icon, i) => (
                <div key={i} className="flex align-items-center">
                  <div className="flex align-items-center action-icon-container circle">
                    <IonIcon src={icon} class="icon circle block action-icon margin-auto" />
                  </div>
                  <span className="item-subtitle">{t(iconNames[i] + 'Text')}</span>
                </div>
              ))}
            </div>
            <IonButton className="main-button" onClick={() => goToCalendar()}>
              {t('understood')}
            </IonButton>
          </div>
        </ActionsAfterNotAppearedContainer>
      </IonContent>
    </IonPage>
  );
};

export default ActionsAfterNotAppeared;
