import { atom } from 'jotai';
import type { SlotAction, SlotStatus } from '../slot';
import type { BookedSlotWithRange } from '@/types/practice';
import type { DriboStudent } from '@/types/user';

export const selectionAtom = atom<BookedSlotWithRange[]>([]);

export const selectedStudentAtom = atom<DriboStudent | undefined>();

export const selectedSlotsStatusAtom = atom<SlotStatus | undefined>();

export const selectedActionAtom = atom<SlotAction>();

export const selectedSlotsIdAtom = atom((get) =>
  get(selectionAtom).map((slot) => slot.__front_uid__),
);

export const multiSelectedDaysAtom = atom((get) => get(selectionAtom).map((slot) => slot.day));

export const numberOfSelectedSlotsAtom = atom((get) => get(selectionAtom).length);

export const multiSelectEnabledAtom = atom((get) => !!get(numberOfSelectedSlotsAtom));

export const daysWithSelectionAtom = atom((get) => get(selectionAtom).map((slot) => slot.date));
