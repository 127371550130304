import localForage from 'localforage';
const appkey = 'teachers-app';

const KEY_STATE = 'dribo-teachers-state';

localForage.config({
  name: appkey,
});

export const read = <T>(key: string) => {
  return localForage.getItem<T>(key);
};

export const write = <T>(key: string, val: any) => {
  return localForage.setItem<T>(key, val);
};

export const remove = (key: string) => {
  return localForage.removeItem(key);
};

export const clear = async () => {
  return localForage.clear();
};

export default {
  KEY_STATE,
  read,
  write,
  remove,
  clear,
};
