import React from 'react';
type TimeStripeProps = {
  isToday: boolean;
  currTime: string;
  hourIndicatorPos: number;
};

export const TimeStripe: React.FC<TimeStripeProps> = ({ isToday, currTime, hourIndicatorPos }) => (
  <div className="time-stripe">
    <div>
      <div className="clock relative">
        {isToday && (
          <div className="current-hour absolute" style={{ top: hourIndicatorPos - 10 + 'px' }}>
            <span>{currTime}</span>
          </div>
        )}
        {[...Array(18).keys()].map((hour) => (
          <div key={hour} className="hour-cell">
            <span className="hour light">{(hour + 6).toString().padStart(2, '0') + ':00'}</span>
            <div className="quarter-divisions">
              <hr />
              <hr className="half-hour" />
              <hr />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
