import React, { useState } from 'react';
import { IonItem, IonLabel, IonInput, IonIcon, IonTextarea } from '@ionic/react';

interface InputProps {
  className?: string;
  onChange: (value: string) => void;
  label: string;
  type?: string;
  value?: string;
  disabled?: boolean;
  icon?: string;
  onIconClick?: (event: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => void;
  autocomplete?: boolean;
  name?: string;
  isTextArea?: boolean;
}
const Input: React.FC<InputProps> = (props) => {
  const { className, icon, onIconClick, ...inputProps } = props;
  const [focused, setFocused] = useState(false);
  const classes = focused ? 'ion-focused' : '';

  return (
    <IonItem lines="none" className={`${classes} ${className ? className : ''}`}>
      <IonLabel position="floating">{props.label}</IonLabel>
      {props.isTextArea ? (
        <IonTextarea
          value={props.value}
          onIonBlur={() => setFocused(false)}
          onIonFocus={() => setFocused(true)}
          onIonChange={(e) => props.onChange(e.detail.value ? e.detail.value : '')}
        />
      ) : (
        <IonInput
          {...(inputProps as any)}
          onIonBlur={() => setFocused(false)}
          onIonFocus={() => setFocused(true)}
          type={(props.type as any) || 'text'}
          onIonChange={(e) => props.onChange(e.detail.value ? e.detail.value : '')}
        />
      )}
      {icon ? (
        <IonIcon
          className="input-icon"
          icon={icon}
          onClick={(event) => onIconClick && onIconClick(event)}
        />
      ) : (
        <></>
      )}
    </IonItem>
  );
};

export default Input;
