import React from 'react';
import { getSlotStatus, SlotStatus } from '@/logic/slot';
import { BookedSlotWithRange } from '@/types/practice';
import type { IconName } from '../icon';
import { UserBadge } from './user-badge';
import { IconBadge } from './icon-badge';

type SlotBadgeProps = {
  slot: BookedSlotWithRange;
  size?: 'small' | 'medium' | 'large';
};

const statusColorMap: Partial<Record<SlotStatus, { color: string; backgroundColor: string }>> = {
  [SlotStatus.PAST_FREE]: { color: 'var(--base-black)', backgroundColor: 'var(--grey-grey-5)' },
  [SlotStatus.LOCKED]: { color: 'var(--base-black)', backgroundColor: 'var(--grey-grey-5)' },
  [SlotStatus.FREE]: {
    color: 'var(--auxiliares-green-2)',
    backgroundColor: 'var(--auxiliares-green-1)',
  },
  [SlotStatus.PRE_BOOKED]: {
    color: 'var(--auxiliares-yellow-2)',
    backgroundColor: 'var(--auxiliares-yellow-1)',
  },
  [SlotStatus.NO_SHOW]: {
    color: 'var(--auxiliares-red-2)',
    backgroundColor: 'var(--auxiliares-red-1)',
  },
};

const statusIconMap: Partial<Record<SlotStatus, IconName>> = {
  [SlotStatus.FREE]: 'event_available',
  [SlotStatus.PAST_FREE]: 'event_busy',
  [SlotStatus.LOCKED]: 'lock',
  [SlotStatus.PRE_BOOKED]: 'person_alert',
  [SlotStatus.NO_SHOW]: 'person_cancel',
};

export const SlotBadge: React.FC<SlotBadgeProps> = ({ slot, size = 'small' }) => {
  const status = getSlotStatus(slot);
  const iconName = statusIconMap[status] || 'add';
  const backgroundColor = statusColorMap[status]?.backgroundColor || '';
  const color = statusColorMap[status]?.color || '';
  return status === SlotStatus.BOOKED ? (
    <UserBadge user={slot.user!} {...{ size }} />
  ) : (
    <IconBadge icon={iconName} color={color} backgroundColor={backgroundColor} />
  );
};
